<ion-item [routerLink]="routerLink" routerDirection="forward" detail="false">
  <ion-thumbnail slot="start" class="collection-img">
    <app-cached-image [src]="collectionImgSrc"></app-cached-image>
  </ion-thumbnail>
  <ion-label class="ion-text-no-wrap">
    <h2>
      {{ designation }}
    </h2>
    <h3>{{ description }}</h3>
  </ion-label>
</ion-item>
