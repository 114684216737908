<ion-item #volume class="ion-no-padding ion-no-margin">
  <div slot="start" class="instrument-img ion-text-center" [id]="popoverTriggerID">
    <ion-thumbnail>
      <app-cached-image [src]="instImgSrc"></app-cached-image>
    </ion-thumbnail>
    <small class="instrument-title ion-no-padding ion-no-margin">{{ designation }}</small><br>
    <app-level-icon [level]="track.level.id" [hasLevels]="hasLevels"></app-level-icon>
  </div>

  <ion-popover  *ngIf="hasPopover" [trigger]="popoverTriggerID" [dismissOnSelect]="true" size="auto" backdropDismiss="true">
    <ng-template>
      <ion-content scrollY="false">
        <ion-list *ngIf="hasInstruments" >
          <ion-list-header>
            {{ "PLAYER.INSTRUMENT" | translate }}
          </ion-list-header>
          <ion-item *ngFor="let track of possibleInstruments"
              [button]="true"
              [detail]="false"
              (click)="switchTrack(track)">
            {{ track.instrument.designation }}
          </ion-item>
        </ion-list>
        <ion-list *ngIf="hasLevels" >
          <ion-list-header>
            {{ "PLAYER.LEVEL" | translate }}
          </ion-list-header>
          <ion-item *ngFor="let track of possibleLevels"
            [button]="true"
            [detail]="false"
            (click)="switchTrack(track)">
          {{ track.level.designation }}
        </ion-item>
        </ion-list>
        <ion-list *ngIf="hasSheets">
          <ion-list-header>
            {{ "PLAYER.SHEETS" | translate }}
          </ion-list-header>
          <ion-item *ngIf="hasEmptySheets" (click)="openEmptySheet()">
            {{ "PLAYER.EMPTYSHEETS" | translate }}
          </ion-item>
          <ion-item *ngIf="hasRhythmSheets" (click)="openRhythmSheet()">
            {{ "PLAYER.RHYTHMSHEETS" | translate }}
          </ion-item>
          <!--<ion-item *ngIf="hasChartSheets" [disabled]="true">
            {{ "PLAYER.BUYPARTS" | translate }}
          </ion-item>-->
          <ion-item *ngIf="hasChartSheets" (click)="openSheetPreview()">
            {{ "PLAYER.COMPLETESHEETS" | translate }}
          </ion-item>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>

  <ion-button fill="clear" (click)="mute()" [disabled]="soloActive" [color]="muteColor" class="mute-button">
    <ion-icon name="volume-mute" class="mute-icon"></ion-icon>
  </ion-button>
  <ion-range min="0" max="1.0" step="0.01" [(ngModel)]="instrVolume"
    (ionInput)="onVolumeChange($event)"
      [disabled]="instrMuted"
      class="volume-slider ion-no-margin"
      aria-label="Volume">
  </ion-range>
  <ion-button fill="clear" color="primary" (click)="solo()" [color]="soloColor" class="solo-button">
    S
  </ion-button>

</ion-item>
