import { LanguageService } from '../languages/language.service';
/* eslint-disable @typescript-eslint/member-ordering */
import { UserModel } from 'bandon-shared';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, OAuthProvider, signInWithPopup } from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { tap, Observable, of, BehaviorSubject, from, Observer } from 'rxjs';
import { Router } from '@angular/router';
import { Glassfy } from 'capacitor-plugin-glassfy';
import { CachingService } from '../caching/caching.service';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { LocalFile } from 'src/app/shared/interfaces/local-file';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isAuthenticated = this.isAuthenticatedSubject.asObservable();
  isRefreshing = false;

  user: UserModel;
  private userSubject = new BehaviorSubject<UserModel>(null);
  user$ = this.userSubject.asObservable();

  userPicture: LocalFile;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  apple_options: SignInWithAppleOptions = {
    clientId: 'com.scherer.musican.auth',
    redirectURI: 'https://ch.musicdose.starter://auth/apple',
    scopes: 'email name',
    state: '12345',
    nonce: 'nonce'
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  apple_web_options: SignInWithAppleOptions = {
    clientId: 'com.scherer.musican.auth',
    redirectURI: 'https://app.band-on.com',
    scopes: 'email name',
    state: '12345',
    nonce: 'nonce'
  };

  private isOnline = false;

  constructor(
      private auth: Auth,
      private httpClient: HttpClient,
      private languageService: LanguageService,
      private router: Router,
      private cachingService: CachingService,
      private connectivityService: ConnectivityService,
      private alertController: AlertController,
      private translate: TranslateService
  ) {
/*    GoogleAuth.initialize({
      clientId: '589593319665-f39ikp8bjs9nm54s8s2tb3g9vhf9ppmo.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: true
    });*/
    GoogleAuth.initialize({scopes: ['profile', 'email'], grantOfflineAccess: true});

    this.connectivityService.appIsOnline$.subscribe(online => {
      this.isOnline = online;
    });

/*    try {
      this.checkAuthenticated()
      .subscribe({
        next: isAuth => {
          this.isAuthenticatedSubject.next(isAuth);
          if(isAuth) {
            this.getUserData();
          }
        },
        error: error => {
          this.isAuthenticatedSubject.next(false);
          console.log('Error in getting Authentication');
        }
      });
    } catch {
      this.isAuthenticatedSubject.next(false);

    }*/
  }

  get isWeb(): boolean {
    return Capacitor.getPlatform()==='web';
  }

  register({ email, username, name, password, agb, newsletter }) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    const hashedPassword = CryptoJS.SHA256(password).toString();
    const newsletterString = JSON.stringify(newsletter);
    const lang = this.languageService.selected;

    return this.httpClient.post(environment.apiURL+'/auth/register', {email, username, name, password: hashedPassword,
        newsletter: newsletterString, language: lang}, {headers}).pipe(
      tap(response => {
        this.storeToken(response);
      })
    );

  }

  login({email, password}) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    const hashedPassword = CryptoJS.SHA256(password).toString();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const data = {password_hash: hashedPassword, email};
    return this.httpClient.post(environment.apiURL+'/auth/login', data, {headers}).pipe(
      tap(response => {
        this.storeToken(response, true);
      })
    );
  }


  async loginWithApple() {
    try {
      if (Capacitor.getPlatform() === 'web') {
        return this.signInWithAppleWeb();
      } else {
        return this.signInWithAppleNative();
      }
    } catch (error) {
      throw Error('Login with Apple: There was an error loggin in: '+error.message);
    }
  }

  async signInWithAppleWeb() {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email name');

    const user = await signInWithPopup(this.auth, provider);
    return this.loginWithCredentials(user.user.uid,
      user.user.displayName ?? user.user.email.substring(0, user.user.email.indexOf('@')),
      user.user.email);
  }

  async signInWithAppleNative() {
/*    const options: SignInWithAppleOptions = {
      clientId: 'ch.musicdose.starter',
      redirectURI: 'https://musican-345011.firebaseapp.com/__/auth/handler',
      scopes: 'email',
      state: '12345',
//      nonce: 'nonce',
    };*/
    const options: SignInWithAppleOptions = {
      clientId: 'com.scherer.musican.auth',
      redirectURI: 'https://ch.musicdose.starter://auth/apple',
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce'
    };

    let result: SignInWithAppleResponse | undefined;
    try {
      result = await SignInWithApple.authorize(options);

      const cred = this.jwt_decode(result.response.identityToken);

      const email = cred.email;
      let emailName = '';
      if (email && email.indexOf('@')) {
        emailName = email.substring(0, email.indexOf('@'));
      }
      return this.loginWithCredentials(result.response.user,
        result.response.givenName ?? emailName,
        email);
    } catch (error) {
      throw Error('Login with Apple Native: There was an error loggin in: '+error.message);
    }

  }

  async loginWithGoogle() {
    const user = await GoogleAuth.signIn();
    const provider = new OAuthProvider('google.com');
    const credential = provider.credential({
      idToken: user.authentication.idToken,
    });
    return this.loginWithCredentials(user.id,
      user.givenName ?? user.email.substring(0, user.email.indexOf('@')),
      user.email);
  }

  async signInWithGoogleWeb() {
    try {
      const provider = new OAuthProvider('google.com');

      const user = await signInWithPopup(this.auth, provider);
//      this.user = user.user;
//      this.updateUserData(user.user.uid, user.user.displayName ?? '', user.user.email);

      return user;
    } catch(e) {
      return null;
    }

  }

  loginWithCredentials(uid: string, name: string, email: string) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.httpClient.post<string>(environment.apiURL+'/auth/login/credentials', {user_id: uid, name, email}, {headers}).pipe(
      tap(response => {
        this.storeToken(response, true);
      })
    );
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    this.cachingService.clearTokens();
    if(this.isAuthenticatedSubject.getValue()) {
      this.isAuthenticatedSubject.next(false);
    }
    this.user = undefined;
    if(this.userSubject.getValue()) {
      this.userSubject.next(null);
    }
    this.router.navigateByUrl('/collection/library');
    this.userPicture = undefined;
  }

  resetPassword(email: string) {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer '+environment.apiKey)
    .set('content-type', 'application/json');
    const body = JSON.stringify({email});
    return this.httpClient.post<any>(environment.apiURL+'/auth/reset_password', body, {headers});
  }

  deleteUser() {
    if(this.user) {
      const headers = new HttpHeaders().set('Authorization', this.getIDToken());
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const decoded_token = this.jwt_decode(this.getToken());
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const user_id = decoded_token.user_id;

      return this.httpClient.put(`${environment.apiURL}/users/${user_id}/deletion`, null, {headers});
    }
  }

  async checkAuthenticated(forceEmit = false) {
    if(this.getToken()) {
      if(!this.isAuthenticatedSubject.getValue() || forceEmit) {
        this.isAuthenticatedSubject.next(true);
        this.getUserData();
      }
      return true;
    } else if(await this.cachingService.getToken()) {
      if(!this.isAuthenticatedSubject.getValue() || forceEmit) {
        localStorage.setItem('token', await this.cachingService.getToken());
        localStorage.setItem('refresh_token', await this.cachingService.getRefreshToken());

        this.isAuthenticatedSubject.next(true);
        this.getUserData();
      }

      return true;
    }
    if(this.isAuthenticatedSubject.getValue() || forceEmit) {
      this.isAuthenticatedSubject.next(false);
    }
    return false;
  }

  getIDToken(): string {
    return 'Bearer '+this.getToken();
  }

  getToken() {
//    return await this.cachingService.getToken()
    return localStorage.getItem('token');
  }

  getRefreshToken() {
//    return await this.cachingService.getRefreshToken()
    return localStorage.getItem('refresh_token');
  }

  checkToken() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+this.getToken());
    return this.httpClient.post<string>(environment.apiURL+'/auth/check_token', null, {headers});
  }

  refreshToken(): Observable<string> {
    this.isRefreshing = true;
    const headers = new HttpHeaders().set('Authorization', 'Bearer '+this.getRefreshToken());
    return this.httpClient.post<string>(environment.apiURL+'/auth/refresh', null, {headers}).pipe(
      tap(response => {
        this.isRefreshing = false;
        this.storeToken(response);
      })
    );
  }

  refreshTokenIfExpired(): Observable<string> {
    const token = this.getToken();
    if (!token) {
      return of(null);
    }

    if (!this.user) {
      this.getUserData();
    }

    const decodedToken = this.jwt_decode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      return this.refreshToken();
    } else {
      if(!this.isAuthenticatedSubject.getValue()) {
        this.isAuthenticatedSubject.next(true);
      }
      return of(token);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  jwt_decode(token: string): any {
    if (!token) {
      throw new Error('No token provided');
    }

    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid token');
    }

    const base64Url = parts[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    try {
      return JSON.parse(window.atob(base64));
    } catch (error) {
      throw new Error('Invalid token');
    }
  }

  updateUserData() {
    if (!this.user) {
      return;
    }

    const headers = new HttpHeaders().set('Authorization', this.getIDToken());

    const data = {
      name: this.user.name,
      username: this.user.username,
      language: this.user.language,
      newsletter: this.user.newsletter,
      instruments: this.user.instruments
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const decoded_token = this.jwt_decode(this.getToken());
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const user_id = decoded_token.user_id;

    return this.httpClient.post<UserModel>(environment.apiURL+`/users/${user_id}`, data, {headers})
      .subscribe(resp => {
        if(this.user !== resp) {
          this.user = resp;
          this.userSubject.next(resp);
          }
      });
  }

  updateUserMail(email) {
    if (!this.user) {
      return;
    }

    const headers = new HttpHeaders().set('Authorization', this.getIDToken());

    const data = {
      email: email,
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const decoded_token = this.jwt_decode(this.getToken());
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const user_id = decoded_token.user_id;

    return this.httpClient.post<UserModel>(environment.apiURL+`/users/${user_id}`, data, {headers})
      .subscribe({
        next: () => {
          this.alertController.create({
            header: this.translate.instant('PROFILE.CONFIRM'),
            message: this.translate.instant('PROFILE.EMAILCONFIRM'),
            buttons: ['Ok'],
          }).then( alert => alert.present())
        },
        error: err => {
          this.alertController.create({
            header: this.translate.instant('PROFILE.ERROR'),
            message: this.translate.instant('PROFILE.ERROREMAIL'),
            buttons: ['Ok'],
          }).then( alert => alert.present())
        }
      });
  }

  public getUserData(forceRefresh = false) {
    // Get user Data
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const decoded_token = this.jwt_decode(this.getToken());
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const user_id = decoded_token.user_id;
    if(!this.isWeb) {
      Glassfy.connectCustomSubscriber({ subscriberId: `${user_id}` });
    }
    return this.getData(environment.apiURL+`/users/${user_id}`, forceRefresh)
//    return this.httpClient.get<UserModel>(environment.apiURL+`/users/${user_id}`, {headers})
      .subscribe({
        next: resp => {
          if(resp && this.user!==resp) {
            this.user = resp;
            this.userSubject.next(resp);
            this.languageService.setLanguage(this.user.language);
            this.checkIfUserPictureExists(this.user.img, forceRefresh);
          }
        },
        error: () => {}
      });
  }

  private storeToken(response, forceRefresh=false) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const token = response['access_token'];
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/dot-notation
    const refresh_token = response['refresh_token'];
    localStorage.setItem('token', token);
    localStorage.setItem('refresh_token', refresh_token);
    this.cachingService.cacheTokens(token, refresh_token);
    if(!this.isAuthenticatedSubject.getValue()) {
      this.isAuthenticatedSubject.next(true);
    }

    this.getUserData(forceRefresh);
  }

  getUserID() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const decoded_token = this.jwt_decode(this.getToken());
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const user_id = decoded_token.user_id;
    return user_id;
  }

  hasUserFullAccess(): boolean {
    if(this.user) {
      for (const role of this.user.roles) {
        for (const privilege of role.privileges) {
          if(privilege.designation==='show_all_tunes') {
            return true;
          }
        }
      }
    }
    return false;
  }

  private getData(url, forceRefresh: boolean): Observable<any> {
    if(!this.isAuthenticatedSubject.getValue()) {
        return of(null);
    }
    if(!this.isOnline || !this.isAuthenticatedSubject.getValue()) {
      return from(this.cachingService.getCachedRequest(url));
    }

    return this.callAndCache(url);
  }

  private callAndCache(url): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.getIDToken());
    return this.httpClient.get(url, {headers}).pipe(
      tap(res => {
        this.cachingService.cacheRequest(url, res);
      })
    );
  }

  checkIfUserPictureExists(img: string | undefined, forceRefresh = false) {
    if(this.getToken()) {
      const headers = new HttpHeaders().set('Authorization', this.getIDToken());
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const decoded_token = this.jwt_decode(this.getToken());
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const user_id = decoded_token.user_id;

      //Check if directory exists and if not create it
  /*    Filesystem.readdir({
        directory: Directory.Cache,
        path: IMAGE_DIR
      }).then(result => {
      }, async error => {
        await Filesystem.mkdir({
          directory: Directory.Data,
          path: IMAGE_DIR
        });
      });*/

      const filename = `${user_id}.jpeg`;
      if(!forceRefresh) {
        Filesystem.readFile({
          directory: Directory.Cache,
          path: `${environment.avatarCacheFolder}/${filename}`,
        }).then(result => {
          const fileType = filename.split('.').pop();
          this.userPicture = {
            name: filename,
            path: `${environment.avatarCacheFolder}/${filename}`,
            data: `data:image/${fileType};base64,${result.data}`
          };
        }, async err => {
          if(img) {
            this.downloadUserPicture(user_id, headers, filename);
          }
        });
      } else {
        if(img) {
          this.downloadUserPicture(user_id, headers, filename);
        }
      }
    }

  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async downloadUserPicture(user_id: string, headers: HttpHeaders, filename: string) {
    try {
      const fileExists = await Filesystem.readFile({
        directory: Directory.Cache,
        path: `${environment.avatarCacheFolder}/${filename}`
      });
      if (fileExists) {
        // If the file exists, delete it
        await Filesystem.deleteFile({
          path: `${environment.avatarCacheFolder}/${filename}`,
          directory: Directory.Cache,
        });
      }
    } catch(error) {
      console.log('Error deleting User-File');
    }
    this.httpClient.get(environment.apiURL+`/users/${user_id}/img`, { headers, responseType: 'blob'})
      .subscribe({
        next: async resp => {
          if (resp) {
            const base64Data = await this.convertBlobToBase64(resp) as string;
            this.userPicture = {
              name: filename,
              path: `${environment.avatarCacheFolder}/${filename}`,
              data: `${base64Data}`
            };
            Filesystem.writeFile({
              directory: Directory.Cache,
              path: `${environment.avatarCacheFolder}/${filename}`,
              data: base64Data
            }).then( e => console.log('User Picture saved'));

//            this.userImg.webPath = `${IMAGE_DIR}/${filename}`;
          }
        },
        error: err => {
          console.log('download User Picture Error');
        }
      });
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

}
