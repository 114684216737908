<!--<ion-toolbar>-->
<div class="slider-container">
  <ion-range [min]="loopStart" [max]="loopEnd" step="0.01" debounce="0"
      [(ngModel)]="currentTime"
      (ionInput)="setCurrentTime($event)" (ionKnobMoveStart)="startJump($event)"
      (ionKnobMoveEnd)="jumpTo($event)"
      aria-label="Current-Time">
  </ion-range>
  <div class="loop-start" *ngIf="showLoop">
    {{ loopStartString }}
  </div>
  <div class="loop-bar-start" *ngIf="showLoop && hasBarInformation">
    {{ loopStartBarString }}
  </div>
  <div class="loop-end" *ngIf="showLoop">
    {{ loopEndString }}
  </div>
  <div class="loop-bar-end" *ngIf="showLoop && hasBarInformation">
    {{ loopEndBarString }}
  </div>
  <div class="time-string ion-text-center">
    {{ currentTimeString }}
  </div>
  <div class="ion-text-center bar-string" *ngIf="hasBarInformation" >
    {{ currentBarString }}
  </div>
</div>
<!--</ion-toolbar>-->
