<swiper-container
  *ngIf="shopWindows.length>0"
  speed="300"
  pagination="false"
  slides-per-view="auto"
  autoplay-delay="3000"
  autoplay-disable-on-interaction="true"
  centered-slides="true"
  class="swiper-container">
  <swiper-slide *ngFor="let window of shopWindows" class="slide" [routerLink]="window.link">
  <app-cached-image [src]="getWindowImgSrc(window)" class="shop-window-img"></app-cached-image>
  </swiper-slide>
</swiper-container>
