import { CachedImgSource } from './../general/cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { LibraryService } from 'src/app/services/library.service';
import { Instrument, Track } from 'bandon-shared';
import { AudioService } from 'src/app/services/audio/audio.service';
import { GestureController, IonicModule } from '@ionic/angular';
import { SheetsService } from 'src/app/services/sheets/sheets.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';
import { LevelIconComponent } from '../level-icon/level-icon.component';
import { CachedImageComponent } from '../general/cached-image/cached-image.component';

@Component({
    selector: 'app-instrument-item',
    templateUrl: './instrument-item.component.html',
    styleUrls: ['./instrument-item.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        CachedImageComponent,
        LevelIconComponent,
        NgIf,
        NgFor,
        FormsModule,
        TranslateModule,
    ],
})
export class InstrumentItemComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('volume', { read: ElementRef }) volume: ElementRef;
  @Input() track: Track;

  @Output() changingVolume: EventEmitter<boolean> = new EventEmitter();

  darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  private lastOnStart = 0;
  private doubleClickThreshold = 500;

  constructor(private libraryService: LibraryService, private audioService: AudioService,
    private gestureCtrl: GestureController, private sheetService: SheetsService,
    private authService: AuthenticationService, private translateService: TranslateService) { }

  get instrument(): Instrument {
    if(this.track) {
      return this.track.instrument;
    }
    return null;
  }

  get designation(): string {
    if(this.track.voice && this.track.voice.id>0) {
      return this.track.voice.designation;
    }
    else if(this.translateService && this.track.instrument) {
      const translation = this.track.instrument.translations.find(e => e.languageid===this.translateService.currentLang);
      if(translation) {
        return translation.designation;
      }
      return this.track.instrument.designation;

    }
    return '';
  }

  get serverUrl(): string {
    return environment.apiURL;
  }

  get instImgSrc(): CachedImgSource {
    const token = 'Bearer '+environment.apiKey;
    return { url: this.instrImgPath, path: this.instrImgPath, token };
  }
  get instrImgPath(): string {
    return this.serverUrl+'/instrImg/'+this.instrument.img;
  }

  get instrImgPathDark(): string {
    if(this.instrument.img_dark) {
      return this.serverUrl+'/instrImg/'+this.instrument.img_dark;
    }
    return this.instrImgPath;
  }


  get instrVolume(): number {
    if (this.instrMuted) {
      return 0;
    }
    return this.track.instrVolume;
  }

  set instrVolume(value) {
    this.track.instrVolume = value;
    this.audioService.setTrackVolume(this.track);
  }

  get instrMuted(): boolean {
    return this.track.muted;
  }

  get instrSolo(): boolean {
    return this.track.solo;
  }

  get soloActive(): boolean {
    return this.audioService.isSoloActive;
  }

  get muteColor(): string {
    if (this.instrMuted) {
      return 'primary';
    }
    return 'light';
  }

  get soloColor(): string {
    if (this.instrSolo) {
      return 'primary';
    }
    return 'light';
  }

  get popoverTriggerID(): string {
    return 'popover-trigger-'+this.track.id;
  }

  /** Returns if the track has a popover, based on multiple instruments for the voice
   * or multiple levels for the instrument.
   */
  get hasPopover(): boolean {
    return this.hasInstruments || this.hasLevels || this.hasSheets;
  }

  get possibleInstruments(): Track[] {
    const out: Track[] = [];
    this.audioService.getTracksToVoice(this.track.voice).forEach((track) =>{
      if (!out.find(e => e.instrument.id===track.instrument.id)) {
          out.push(track);
        }
    });
    return out;
  }

  get possibleLevels(): Track[] {
    const out: Track[] = [];
    this.audioService.getTracksToInstrument(this.track.instrument, this.track.voice).forEach((track) =>{
      if (!out.find(e => e.level.id===track.level.id)) {
          out.push(track);
        }
    });
    out.sort((a, b) => a.level.short > b.level.short ? 1 : -1);

    return out;
  }

  get hasLevels(): boolean {
    return this.possibleLevels.length>1 && !this.track.instrumentgroup.linked;
  }

  get hasInstruments(): boolean {
    return this.possibleInstruments.length>1;
  }

  get hasSheets(): boolean {
    return this.track.sheets && this.track.sheets.length>0;
  }

  get hasEmptySheets(): boolean {
    for (const sheet of this.track.sheets) {
      //TODO: Make this a parameter
      if (sheet.type.id === 1) {
        return true;
      }
    }
    return false;
  }

  get hasRhythmSheets(): boolean {
    for (const sheet of this.track.sheets) {
      //TODO: Make this a parameter
      if (sheet.type.id === 2) {
        return true;
      }
    }
    return false;
  }

  get hasChartSheets(): boolean {
    for (const sheet of this.track.sheets) {
      //TODO: Make this a parameter
      if (sheet.type.id === 3) {
        return true;
      }
    }
    return false;
  }

  ngOnInit() { }

  ngAfterViewInit() {
/*    const gesture: Gesture = this.gestureCtrl.create({
      el: this.volume.nativeElement,
      threshold: 0,
      gestureName: 'resetVolume',
      onStart: () => { this.onStart(); }
    });

    gesture.enable();*/
  }

  ngOnDestroy(): void {
  }

  mute() {
    this.audioService.muteTrack(this.track, !this.instrMuted);
  }

  solo() {
    this.audioService.soloTrack(this.track);
  }

  switchTrack(newTrack: Track) {
    this.audioService.switchTrack(this.track, newTrack);
  }

  private onStart() {
    const now = Date.now();

    if (Math.abs(now - this.lastOnStart) <= this.doubleClickThreshold) {
      this.lastOnStart = 0;
      this.instrVolume = 1.0;
    } else {
      this.lastOnStart = now;
    }
  }

  onVolumeChange(event: any) {
    this.instrVolume = event.detail.value;
  }

  openEmptySheet() {
    for (const sheet of this.track.sheets) {
      if(sheet.type.id === 1) {
        this.sheetService.loadSheet(this.designation, sheet);
      }
    }
  }

  openRhythmSheet() {
    for (const sheet of this.track.sheets) {
      if(sheet.type.id === 2) {
        this.sheetService.loadSheet(this.designation, sheet);
      }
    }
  }

  openSheetPreview() {
    for (const sheet of this.track.sheets) {
      if(sheet.type.id === 3) {
        this.sheetService.loadSheet(this.designation, sheet, true);
      }
    }
  }
}
