import { CUSTOM_ELEMENTS_SCHEMA, Component, OnDestroy, OnInit } from '@angular/core';
import { ShopWindow } from 'bandon-shared';
import { CachedImgSource, CachedImageComponent } from '../../general/cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, takeUntil } from 'rxjs';
import { StoreService } from 'src/app/services/store/store.service';
import { IonicModule } from '@ionic/angular';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-shop-window',
    templateUrl: './shop-window.component.html',
    styleUrls: ['./shop-window.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        RouterLink,
        IonicModule,
        CachedImageComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ShopWindowComponent  implements OnInit, OnDestroy {


  private unsubscribe$ = new Subject<void>();
  private isAuth = false;

  constructor(
    private authService: AuthenticationService,
    private httpClient: HttpClient,
    private storeService: StoreService
  ) { }

  get shopWindows(): ShopWindow[] {
    return this.storeService.shopWindows;
  }

  ngOnInit() {
    //Get all Shop Windows
    const headers = new HttpHeaders();
    if (this.isAuth) {
      headers.set('Authorization', this.authService.getIDToken());
    } else {
      headers.set('Authorization', 'Bearer '+environment.apiKey);
    }

    this.httpClient.get<ShopWindow[]>(environment.apiURL+'/shop/windows', {headers})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (windows) => {
          this.shopWindows.length = 0;
          this.shopWindows.push(...windows);
        }
      );

    this.authService.isAuthenticated
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(auth => this.isAuth = auth);
  }

  ngOnDestroy(): void {

  }

  getWindowImgSrc(window: ShopWindow): CachedImgSource {
    if(window.picture) {
      const token = 'Bearer '+environment.apiKey;
      return { url: environment.apiURL+'/photoImg/'+window.picture.id, path: window.picture.path, token };
    }
    return { url: '', path: '', token: '' };
  }

}
