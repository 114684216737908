import { AudioEngine } from '@musicdose/audioengine';
import { LibraryService } from 'src/app/services/library.service';
import { Router } from '@angular/router';
import { CachedImgSource, CachedImageComponent } from '../cached-image/cached-image.component';
import { Playlist, SimpleTuneModel, TrackFile } from 'bandon-shared';
import { Component, OnInit, Input, OnDestroy, NgZone, ViewChild, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { Subject, takeUntil } from 'rxjs';
import { AudioDownloadService } from 'src/app/services/audio/audio-download.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { BandONTranslationsService } from 'src/app/services/languages/band-ontranslations.service';
import { TranslateModule } from '@ngx-translate/core';
import { LoadTuneButtonComponent } from '../load-tune-button/load-tune-button.component';
import { NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';

interface TrackState {
  trackid: number;
  downloaded: boolean;
}

@Component({
    selector: 'app-tune-item',
    templateUrl: './tune-item.component.html',
    styleUrls: ['./tune-item.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgIf,
        CachedImageComponent,
        LoadTuneButtonComponent,
        TranslateModule,
    ],
})
export class TuneItemComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() tune: SimpleTuneModel;
  @Input() showSearchHints = true;
  @Input() isReordable = false;
  @Input() showRemove = false;
  @Input() showAddToPl = false;
  @Input() isInPlaylist = false;
  @Input() activePlaylist: Playlist | undefined;
  @Input() plTuneIndex = -1;
  @Input() showSubtitle = true;

  @Output() removeTune: EventEmitter<SimpleTuneModel> = new EventEmitter<SimpleTuneModel>();
  @Output() addTune: EventEmitter<void> = new EventEmitter<void>();
  @Output() addTuneToPL: EventEmitter<SimpleTuneModel> = new EventEmitter<SimpleTuneModel>();

  @ViewChild('tuneItem', { read: ElementRef }) tuneItemRef: ElementRef;

  public downloadProgress = 0;

  private unsubscribe$ = new Subject<void>();
  private tracks: TrackFile[] = [];

  private trackStates: TrackState[] = [];
  private hostDeleteAnimation;

  constructor(
    private router: Router,
    private libraryService: LibraryService,
    private audioDownloadService: AudioDownloadService,
    private authService: AuthenticationService,
    private ngZone: NgZone,
    private bandonTranslations: BandONTranslationsService
  ) { }

  get tuneImgSrc(): CachedImgSource {
    if(this.tune && this.tune.picture) {
      const token = 'Bearer '+environment.apiKey;
      return { url: environment.apiURL+'/photoImg/'+this.tune.picture.id, path: this.tunePath, token };
    }
    return { url: '', path: '', token: '' };
  }

  get tunePath(): string {
    if(this.tune && this.tune.picture) {
      return this.tune.picture.path;
    }
    return '';
  }

  get title(): string{
    return this.bandonTranslations.getTuneTitle(this.tune);
  }

  get description(): string {
    if (this.tune) {
      return this.tune.tonalities+', '+this.tune.speeds;
    }
    return '';
  }

  get isWeb() {
    return Capacitor.getPlatform() === 'web';
  }

  get downloadState(): string {
    if(this.trackStates.length>0) {
      return 'downloading';
    } else if(this.tune) {
      return this.libraryService.getDownloadState(this.tune.id);
    }
    return 'unknown';
  }

  get tuneFolder(): string {
    if (this.tune) {
      //TODO: Check if this works every time
      return 'Tunes/'+this.tune.id+'-'+this.tune.title;
    }
    return '';
  }

  get ariaText(): string {
    //TODO: translation
    if(this.tune) {
      let tonalityString = 'Tonart';
      if(this.tune.tonalities.split(',').length>1) {
        tonalityString = 'Tonarten'
      }
      let tempoString = 'Tempo';
      if(this.tune.speeds.split(',').length>1) {
        tempoString = 'Tempi';
      }
      let out = `Stück ${this.title}, ${tonalityString} ${this.tune.tonalities}, ${tempoString} ${this.tune.speeds}`;
      return out;
    }
    return 'keine Informationen';
  }

  async ngOnInit() {
    this.libraryService.tracks$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((tracks) => {
      this.tracks.length = 0;
      this.tracks.push(...tracks);
    });

    this.audioDownloadService.progress$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(progress => {

      this.ngZone.run(() => {
        this.downloadProgress = progress;
      });
    });
  this.audioDownloadService.trackCompleted$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async trackCompleted => {
      const trackFile = this.trackStates.find(f => f.trackid===trackCompleted);
      if (trackFile) {
        trackFile.downloaded = true;
      }

      let downloaded = this.trackStates.length > 0;
      for (const track of this.trackStates) {
        downloaded = downloaded && track.downloaded;
      }
      if(downloaded) {
        await this.libraryService.updateTuneDownloadedState(this.tune.id, true);

        this.trackStates.length = 0;
        this.downloadProgress = 0;
      }
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openTune() {
    if(this.tune && !this.isReordable && !this.showAddToPl) {
      if(this.activePlaylist) {
        this.router.navigateByUrl(`/tune/${this.activePlaylist.id}/${this.plTuneIndex}`);
      } else {
        this.router.navigateByUrl(`/tune/${this.tune.id}`);
      }
    } else if(this.tune && this.showAddToPl) {
      this.triggerAddToPl();
    } else if(!this.tune && this.showRemove) {
      this.triggerAdd();
    }
  }

  triggerAdd() {
    this.addTune.emit();
  }

  triggerAddToPl() {
    this.addTuneToPL.emit(this.tune);
  }

  triggerRemove() {
    this.removeTune.emit(this.tune);
  }


  downloadTune(event: any) {
    event.stopPropagation();
  }

  async deleteFiles() {
    await AudioEngine.removeFiles({path: this.tuneFolder});
    this.libraryService.updateTuneDownloadedState(this.tune.id, true);
  }

  async downloadFiles() {
    const trackIDs = this.tune.tracks.split(',');
    const speedIDs = this.tune.speedids.split(',');
    (await this.libraryService.loadTuneFromServer(this.tune.id, false))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: tune => {
        for (const speedID of speedIDs) {
          const speed = this.libraryService.speeds.find(s => s.id===Number(speedID));
          if(speed) {
            const options = {
              accessToken: this.authService.getToken(),
              tuneSpeedId : speed.id,
              filePath: speed.countofffile,
              fileMD5: speed.countoffchecksum
            };
            AudioEngine.downloadCountOff(options);
            }
    //      this.audioDownloadService.downloadFile(0, track.path, speed.countoffchecksum, speed.);

        }

        for (const trackID of trackIDs) {
          const track = this.libraryService.tracks.find(t => t.id===Number(trackID));
          if(track) {
            this.trackStates.push({
              trackid: track.id,
              downloaded: false
            });
            this.audioDownloadService.downloadFile(track.id, track.path, track.filechecksum, track.filesize);
          }
        }

        //TODO: download image
      },
      error: e => {
        console.log('error Downloaded tune: ', this.tune.id, e);
      }
    });


  }

}
