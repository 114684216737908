import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            // if control is empty return no error
            return null;
        }

        // test the value of the control against the regexp supplied
        const valid = regex.test(control.value);

        // if true, return no error, else return error passed in the second parameter
        return valid ? null : error;
    };
}

static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password')?.value;
    const confirmPassword: string = control.get('confirmPassword')?.value;

    if (password !== confirmPassword) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        control.get('confirmPassword')?.setErrors({ NoPasswordMatch: true});
    }
}
}
