import { QRCodeModule } from 'angularx-qrcode';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';

@Component({
  selector: 'app-user-qr',
  templateUrl: './user-qr.component.html',
  styleUrls: ['./user-qr.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    QRCodeModule,
  ]
})
export class UserQrComponent  implements OnInit {
  @Input() qrString: string;

  authService = inject(AuthenticationService)
  modalController = inject(ModalController)

  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
