import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ArrangementShowPart, Part, Tune } from 'bandon-shared';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TuneInfoService } from 'src/app/services/audio/tune-info.service';
import { NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AudioService } from 'src/app/services/audio/audio.service';
import { FormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

export interface LoopBeatDef {
  startBeat: number;
  endBeat: number;
}

export interface LoopTimesDef {
  startTime: number;
  endTime: number;
}

@Component({
    selector: 'app-arrangement-part',
    templateUrl: './arrangement-part.component.html',
    styleUrls: ['./arrangement-part.component.scss'],
    animations: [
        trigger('cardAnimation', [
            state('false', style({
                height: '70px',
            })),
            state('true', style({
                height: '150px',
            })),
            transition('* <=> *', [
                animate('0.3s ease')
            ])
        ])
    ],
    standalone: true,
    imports: [IonicModule, NgIf, FormsModule]
})
export class ArrangementPartComponent  implements OnInit, OnDestroy {
  audioService = inject(AudioService);
  tuneInfoService = inject(TuneInfoService)
  translateService = inject(TranslateService)

  @ViewChild('partCard', {static: true}) partCard: ElementRef;


  @Input() tune: Tune;
  @Input() part: ArrangementShowPart;
  @Input() isActive = true;
  @Input() isStartLoop = false;
  @Input() isEndLoop = false;

  @Output() setLoopStartPart: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setLoopEndPart: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() setLoopTimes: EventEmitter<LoopTimesDef> = new EventEmitter<LoopTimesDef>();

  @Output() jumpToPart: EventEmitter<ArrangementShowPart> = new EventEmitter<ArrangementShowPart>();

  private isChanging = false;
  private singleRangeValue = 0;
  private doubleRangeValue = { lower: 0, upper: 1e6 };

  private unsubscribe$ = new Subject<void>();

  get cssClass() {
    let out = 'part-card disabled';
    if (this.tuneInfoService.isInPickup && this.tuneInfoService.nextPart===this.part) {
      out = 'part-card';
    } else if (this.tuneInfoService.currentShowPart===this.part) {
      out = 'part-card';
    } else if (this.isActive) {
      out = 'part-card not-current';
    }
    return out;
  }

  get titleColor(): string {
    let out = 'light';
    if (this.tuneInfoService.isInPickup && this.tuneInfoService.nextPart===this.part) {
      out = 'secondary';
    } else if (this.tuneInfoService.currentShowPart===this.part) {
      out = 'secondary';
    }
    return out;
  }

  get showRange(): boolean {
    return this.isStartLoop || this.isEndLoop;
  }

  get isDualKnob(): boolean {
    return this.isStartLoop && this.isEndLoop;
  }

  get getStartLoopColor(): string {
    if (this.isStartLoop) {
      if (this.tuneInfoService.isInPickup && this.tuneInfoService.nextPart===this.part) {
        return 'primary';
      } else if (this.tuneInfoService.currentShowPart===this.part) {
        return 'primary';
      }
      return 'light';
    }
    return 'medium';
  }

  get getEndLoopColor(): string {
    if (this.isEndLoop) {
      if (this.tuneInfoService.isInPickup && this.tuneInfoService.nextPart===this.part) {
        return 'primary';
      } else if (this.tuneInfoService.currentShowPart===this.part) {
        return 'primary';
      }
      return 'light';
    }
    return 'medium';
  }

  get rangeMinValue(): number {
    //TODO: Different Meters in Tune
    if(this.hasBarInformation) {
      return 0;
    }
    return this.part.timestart;
}

  get rangeMaxValue(): number {
    //TODO: Different Meters in Tune
    if(this.hasBarInformation) {
      return this.part.beatsCount;
    }
    return this.part.timeend;
  }

  get hasBarInformation(): boolean {
    if(this.part && this.part.showbarslider) {
      return true;
    }
    return false;
  }

/*  get rangeValue(): any {
    if(this.isDualKnob) {
      return { lower: this.rangeMinValue, upper: this.rangeMaxValue };
    } else if(this.isEndLoop) {
      return this.rangeMaxValue;
    }
    return this.rangeMinValue;
  }*/

  get rangeValue(): any {
    if(this.isDualKnob) {
      return this.doubleRangeValue;
    }
    return this.singleRangeValue;
  }

  set rangeValue(value: any) {
    if(this.isDualKnob) {
      this.doubleRangeValue = value;
    } else {
      this.singleRangeValue = value;
    }
  }

  get dualRangeValue(): any {
    return { lower: this.rangeMinValue, upper: this.rangeMaxValue };
  }

  get cardID(): string {
    return `ArrangementPartCard${this.part.id}`;
  }

  get cardContentClass(): string {
    if(this.isStartLoop && !this.isEndLoop) {
      if (this.tuneInfoService.isInPickup && this.tuneInfoService.nextPart===this.part) {
        return 'card-content start-range-active';
      } else if (this.tuneInfoService.currentShowPart===this.part) {
        return 'card-content start-range-active';
      } else {
        return 'card-content start-range';
      }
    } else if(!this.isStartLoop && this.isEndLoop) {
      if (this.tuneInfoService.isInPickup && this.tuneInfoService.nextPart===this.part) {
        return 'card-content end-range-active';
      } else if(this.tuneInfoService.currentShowPart===this.part) {
        return 'card-content end-range-active';
      } else if(!this.isStartLoop && this.isEndLoop) {
        return 'card-content end-range';
      }
    }
    return 'card-content';
  }

  ngOnInit() {
    this.tuneInfoService.loopStartDef$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(def => {
        if(def && this.tuneInfoService.loopStartPart===this.part) {
          if(this.part.showbarslider) {
            const beat = Math.round(def.beatNumber+def.beatProgress);
            this.doubleRangeValue.lower = beat;
            this.singleRangeValue = beat;
          } else {
            const duration = (this.part.timeend-this.part.timestart);
            const start = this.part.timestart;
            const timestamp = start + def.partProgress * duration
            this.doubleRangeValue.lower = timestamp;
            this.singleRangeValue = timestamp;
          }
        }
      });

    this.tuneInfoService.loopEndDef$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(def => {
        if(def && this.tuneInfoService.loopEndPart===this.part) {
          if(this.part.showbarslider) {
            const beat = Math.round(def.beatNumber+def.beatProgress);
            this.doubleRangeValue.upper = beat;
            this.singleRangeValue = beat;
          } else {
            const duration = (this.part.timeend-this.part.timestart);
            const start = this.part.timestart;
            const timestamp = start + def.partProgress * duration
            this.doubleRangeValue.upper = timestamp;
            this.singleRangeValue = timestamp;
          }
        }
      });

  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  pinFormatter = (value: number) => {
    if(this.hasBarInformation) {
      const beatInfo = this.tuneInfoService.getBeatInfo(this.part, value);
      return `${this.translateService.instant('ARRANGEMENT.BAR')} ${beatInfo.bar},
        ${this.translateService.instant('ARRANGEMENT.BEAT')} ${beatInfo.beat}`;
    }
    return `${this.numberWithCommas(value)}`;
  };

  numberWithCommas(x: any) {
    const millis = Number(x);
    if (millis) {
      let minutes = 0;
      if (millis>=60000) {
        minutes = Math.floor(millis/60000);
      }
      const seconds = Math.floor((millis-minutes*60000)/1000.0);
      const milliseconds = Math.floor((millis-minutes*60000-seconds*1000)/100);
      return minutes.toFixed(0)+':'+seconds.toString().padStart(2, '0')+'.'+milliseconds.toFixed(0).toString();
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  switchStartLoopState() {
    this.part.beatLoopStart = 0;
    this.setLoopStartPart.emit(!this.isStartLoop);
  }

  switchEndLoopState() {
    this.part.beatLoopEnd = 0;
    this.setLoopEndPart.emit(!this.isEndLoop);
  }

  loopChange(event) {
    if(this.isDualKnob) {
      if(this.hasBarInformation) {
        this.part.beatLoopStart = event.detail.value.lower;
        this.part.beatLoopEnd = event.detail.value.upper;
        const loopStartTime = this.tuneInfoService.getLoopStartTime(this.part);
        const loopEndTime = this.tuneInfoService.getLoopEndTime(this.part);
        this.setLoopTimes.emit({startTime: loopStartTime, endTime: loopEndTime});
      } else {
        this.part.timeLoopStart = event.detail.value.lower;
        this.part.timeLoopEnd = event.detail.value.upper;
        this.setLoopTimes.emit({startTime: this.part.timeLoopStart/1000, endTime: this.part.timeLoopEnd/1000});
      }
    } else {
      const value = event.detail.value;
      if(this.isStartLoop) {
        if(this.hasBarInformation) {
          this.part.beatLoopStart = value;
          const loopStartTime = this.tuneInfoService.getBeatInfo(this.part, this.part.beatLoopStart);
          this.setLoopTimes.emit({startTime: loopStartTime.time, endTime: -1});
        } else {
          this.part.timeLoopStart = value;
          this.setLoopTimes.emit({startTime: value/1000, endTime: -1});
        }
      } else if(this.isEndLoop) {
        if(this.hasBarInformation) {
          this.part.beatLoopEnd = value;
          const loopEndTime = this.tuneInfoService.getBeatInfo(this.part, this.part.beatLoopEnd);
          this.setLoopTimes.emit({startTime: -1, endTime: loopEndTime.time});
        } else {
          this.part.timeLoopEnd = value;
          this.setLoopTimes.emit({startTime: -1, endTime: value/1000});
        }
      }
    }
    this.isChanging = false;
  }

  jump() {
    this.jumpToPart.emit(this.part);
  }

  setIsChanging(isChanging: boolean) {
    this.isChanging = isChanging;
  }
}
