import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { IonicModule } from '@ionic/angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-load-tune-button',
    templateUrl: './load-tune-button.component.html',
    styleUrls: ['./load-tune-button.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IonicModule,
        NgCircleProgressModule,
        TranslateModule,
    ],
})
export class LoadTuneButtonComponent  implements OnInit {

  @Input() tuneID: number;
  @Input() fileState: string;
  @Input() progress: number;

  @Output() deleteFiles: EventEmitter<void> = new EventEmitter<void>();
  @Output() startDownload: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('popupmenu') popoverRef;

  isOpen = false;

  constructor() { }

  get popoverTriggerID(): string {
    return 'loadTuneMenuID'+this.tuneID;
  }

  ngOnInit() {}

  removeFiles() {
    this.deleteFiles.emit();
  }

  initDownload() {
    this.startDownload.emit();
  }

  showPopupMenu(e: Event) {
    this.popoverRef.event = e;
    this.isOpen = true;
  }
}
