import { LoadingController, ModalController } from '@ionic/angular';
/* eslint-disable max-len */
import { AlertController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable, OnInit } from '@angular/core';
import { Glassfy, GlassfyAccountableSku, GlassfyOffering, GlassfyPermission, GlassfySku } from 'capacitor-plugin-glassfy';
import { Capacitor } from '@capacitor/core';
import { BandonPurchase, CollectionSku, TuneSku } from 'src/app/shared/interfaces/tune-sku';
import { LibraryService } from '../library.service';
import { AuthenticationService } from '../auth/authentication.service';
import { Collection, PricingCategory, ShopWindow, SimpleTuneModel } from 'bandon-shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CachingService } from '../caching/caching.service';
import { BandONTranslationsService } from '../languages/band-ontranslations.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  tuneSkus: TuneSku[] = [];
  offeredTunes: SimpleTuneModel[] = [];

  collectionSkus: CollectionSku[] = [];
  offeredCollections: Collection[] = [];

  shopWindows: ShopWindow[] = [];

  private offerings: BehaviorSubject<GlassfyOffering[]> = new BehaviorSubject([]);
  private permissions: BehaviorSubject<GlassfyPermission[]> = new BehaviorSubject([]);
  private purchases: BehaviorSubject<GlassfyAccountableSku[]> = new BehaviorSubject([]);

  private consumables: GlassfyOffering;

  private pricingCategories: PricingCategory[] = [];

  private isAuth = false;

  private unregisteresPurchases: BandonPurchase[] = [];
  private sentRegistrationRequests: BandonPurchase[] = [];

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private libraryService: LibraryService,
    private authService: AuthenticationService,
    private httpClient: HttpClient,
    private loadingController: LoadingController,
    private translate: TranslateService,
    private router: Router,
    private cachingService: CachingService,
    private bandonTranslations: BandONTranslationsService
  ) {
    this.initGlassfy();

    this.authService.isAuthenticated
    .subscribe(auth => this.isAuth = auth);
  }

  get isWeb(): boolean {
    return Capacitor.getPlatform()==='web';
  }


  async initGlassfy() {
    if(this.isWeb) {
      return;
    }

    this.authService.user$.subscribe(auth => {
      this.refreshOfferings();
//      if(!auth) {
//        this.tuneSkus.length = 0;
//        this.offeredTunes.length = 0;
//        this.collectionSkus.length = 0;
//        this.offeredCollections.length = 0;
//      }
    });

    try {
//      console.log('Init Glassfy');
      await Glassfy.initialize( {
        apiKey: environment.glassfyKey, watcherMode: false
      });

      const offerings = await Glassfy.offerings();
      this.offerings.next(offerings.all);

//      const permissions = await Glassfy.permissions();
//      this.handleExistingPermissions(permissions.all);

      this.libraryService.collections$.subscribe(collections => {
        this.refreshOfferings();
      });
    } catch (e) {
      // initialization error
    }
  }

  async refreshOfferings() {
    const offerings = await Glassfy.offerings();
    this.offerings.next(offerings.all);
    this.consumables = offerings.all.find(o => o.offeringId==='Consumables');

    //Load all Pricing Categories
    let headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    if (this.isAuth) {
      headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    }
    this.httpClient.get<PricingCategory[]>(environment.apiURL+'/pricing/categories', {headers})
    .subscribe({
      next: categories => {
        this.pricingCategories.length = 0;
        this.pricingCategories = categories;

        this.updateCollectionOfferings();
      },
      error: err => {
        console.log('refreshOfferings, Pricing Categories error: ', err);
      }
    });

    this.libraryService.getTunes()
      .subscribe(tunes => {
        for(const tune of tunes) {
          if(tune.glassfysku) {
            const sku = this.consumables.skus.find(s => s.skuId===tune.glassfysku);
            let tuneSku = this.tuneSkus.find(s => s.tune.id===tune.id);
            if(!tuneSku && sku) {
              tuneSku = {
                sku,
                tune,
                isPurchased: this.isTunePurchased(tune)
              };
              this.tuneSkus.push(tuneSku);
              this.offeredTunes.push(tune);
            } else if(tuneSku) {
              tuneSku.isPurchased = this.isTunePurchased(tune);
            }
          }
        }
        this.tuneSkus.sort((s1, s2) => {
          if(s1.isPurchased!==s2.isPurchased) {
            if(s1.isPurchased) {
              return 1;
            }
            return -1;
          }
          const title1 = this.bandonTranslations.getTuneTitle(s1.tune);
          const title2 = this.bandonTranslations.getTuneTitle(s2.tune);
          return title1.localeCompare(title2);
        });

      });
//    const permissions = await Glassfy.permissions();
//    this.handleExistingPermissions(permissions.all);

    this.registerAllPurchases();
  }

  refreshShopWindows() {
    //Get all Shop Windows
    const headers = new HttpHeaders();
    if (this.isAuth) {
      headers.set('Authorization', this.authService.getIDToken());
    } else {
      headers.set('Authorization', 'Bearer '+environment.apiKey);
    }

    this.httpClient.get<ShopWindow[]>(environment.apiURL+'/shop/windows', {headers})
      .subscribe(
        (windows) => {
          this.shopWindows.length = 0;
          this.shopWindows.push(...windows);
        }
      );
  }

  updateCollectionOfferings() {
    console.log('updateCollectionOfferings');
    const collections = this.libraryService.collectionsBehaviour.getValue();
    this.collectionSkus.length = 0;
    this.offeredCollections.length = 0;

    for(const collection of collections) {
      console.log(`updateCollectionOfferings, check collection ${collection.designation}`);
      if(collection.pricinghistory) {
        const pricing = collection.pricinghistory.find(p => !p.end);
        if(pricing) {
          const cat = this.pricingCategories.find(c => c.id===pricing.pricingcategoryid);
          if(cat && this.consumables) {
            const sku = this.consumables.skus.find(s => s.skuId===cat.glassfysku);
            let collectionSku = this.collectionSkus.find(s => s.collection.id===collection.id);
            if(!collectionSku && sku) {
              collectionSku = {
                sku, collection, isPurchased: this.isCollectionPurchased(collection), reductions: []
              };
              for(const reduction of cat.reductions) {
                const skuReduced = this.consumables.skus.find(s => s.skuId===reduction.glassfysku);
                if(skuReduced) {
                  collectionSku.reductions.push({tunecount: reduction.tunecount, skuReduced});
                } else {
                  console.log(`ERROR: updateCollectionOfferings: couldn't find sku: ${reduction.glassfysku}`);
                }
              }
              console.log('updateCollectionOfferings: add collection ', collectionSku.collection.designation);
              this.collectionSkus.push(collectionSku);
              this.offeredCollections.push(collection);
            } else if(collectionSku) {
              collectionSku.isPurchased = this.isCollectionPurchased(collection);
            } else {
              console.log(`ERROR: updateCollectionOfferings no collectionSku ${collection.designation}`);
            }
/*            const skuReduced1 = this.consumables.skus.find(s => s.skuId===`${cat.glassfysku}_reduced_1`);
            const skuReduced2 = this.consumables.skus.find(s => s.skuId===`${cat.glassfysku}_reduced_2`);
            const skuReduced3 = this.consumables.skus.find(s => s.skuId===`${cat.glassfysku}_reduced_3`);
            let collectionSku = this.collectionSkus.find(s => s.collection.id===collection.id);
            if(!collectionSku && sku && skuReduced1 &&
                ((collection.tunes.length>3 && skuReduced2) || collection.tunes.length<=3 ) &&
                ((collection.tunes.length>4 && skuReduced3) || collection.tunes.length<=4 )) {
              collectionSku = {
                sku,
                skuReduced1, skuReduced2, skuReduced3,
                collection,
                isPurchased: this.isCollectionPurchased(collection)
              };
              this.collectionSkus.push(collectionSku);
              this.offeredCollections.push(collection);
            } else if(collectionSku) {
              collectionSku.isPurchased = this.isCollectionPurchased(collection);
            }*/
          }
        }
      }
    }
    this.collectionSkus.sort((s1, s2) => {
      if(s1.isPurchased!==s2.isPurchased) {
        if(s1.isPurchased) {
          return 1;
        }
        return -1;
      }
      return s1.collection.designation.localeCompare(s2.collection.designation);
    });

  }

  getOfferings() {
    return this.offerings.asObservable();
  }

  async showNotLoggedInAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('STORE.ALERT'),
      message: this.translate.instant('STORE.NEEDSACCOUNT'),
      buttons: [
        {
          text: this.translate.instant('STORE.REGISTER'),
          handler: () => {
            this.router.navigateByUrl('/collection/user/register');
            this.modalController.dismiss();
          }
        },
        {
          text: this.translate.instant('STORE.SIGNIN'),
          handler: () => {
            this.router.navigateByUrl('/collection/user/login');
            this.modalController.dismiss();
          }
        }
      ]
    });

    await alert.present();
  }

  async purchaseTune(tuneSku: TuneSku) {
    if(tuneSku.isPurchased) {
      return;
    }

    if(!this.isAuth) {
      this.showNotLoggedInAlert();
      return;
    }

    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

    try {

      await Glassfy.purchaseSku({ sku: tuneSku.sku });

      this.putPurchaseToDB(loading, tuneSku.tune.id, 0, tuneSku.sku.skuId, tuneSku.sku.product.price, tuneSku.sku.product.currencyCode);

    } catch (e) {
      const alert = await this.alertController.create({
        header: this.translate.instant('STORE.PURCHASEFAILED'),
        message: this.translate.instant('STORE.PURCHASEFAILEDMSG'),
        buttons: ['OK']
      });
      await loading.dismiss();
      await alert.present();
    }
  }

  registerAllPurchases() {
    this.cachingService.getUnregisteredPurchases()
    .then(async purchases => {
      if(purchases) {
        this.unregisteresPurchases = [...purchases];
        const loading = await this.loadingController.create({
          cssClass: 'band-on-loading'
        });
//        await loading.present();

        const unregPurchases = [...purchases];
        for (const p of unregPurchases) {
          const sentRequest = this.sentRegistrationRequests.find(r => (r.tuneid===p.tuneid || r.collectionid===p.collectionid) && r.sku===p.sku);

          if(!sentRequest) {
            this.sentRegistrationRequests.push(p);
            this.putPurchaseToDB( loading, p.tuneid, p.collectionid, p.sku, p.price, p.currency);
          }
        }
      }
    });
  }

  putPurchaseToDB(loading: HTMLIonLoadingElement, tuneid: number, collectionid: number, sku: string, price: number, currency: string) {
    //Register it on band-on-server
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    let data: BandonPurchase = {
      tuneid,
      sku,
      price,
      currency
    };
    if (collectionid>0) {
      data = {
        collectionid,
        sku,
        price,
        currency
      };
    }

    this.httpClient.put(`${environment.apiURL}/users/${this.authService.getUserID()}/purchase`, data, {headers})
    .subscribe({
      next: () => {
        const purchase = this.unregisteresPurchases.find(p => p.tuneid===data.tuneid || p.collectionid===data.collectionid);
        if(purchase) {
          this.unregisteresPurchases.splice(this.unregisteresPurchases.indexOf(purchase), 1);
          this.cachingService.cacheUnregisteredPurchases(this.unregisteresPurchases);
        }

        this.libraryService.loadUserLibraryFromServer(true);
        this.authService.getUserData();

        const sentRequests = this.unregisteresPurchases.filter(p => (p.tuneid===tuneid || p.collectionid===collectionid) && p.sku===sku);
        sentRequests.forEach(r => this.sentRegistrationRequests.splice(this.sentRegistrationRequests.indexOf(r), 1));
        loading.dismiss();
      },
      error: async () => {
        const alert = await this.alertController.create({
          header: this.translate.instant('STORE.ALERT'),
          message: this.translate.instant('STORE.PURCHASEERRSERVER'),
          buttons: [
            {
              text: this.translate.instant('STORE.CANCEL'),
              role: 'cancel',
              handler: () => {
                const purchase = this.unregisteresPurchases.find(p => p.tuneid===data.tuneid || p.collectionid===data.collectionid);
                if(!purchase) {
                  this.unregisteresPurchases.push(data);
                  this.cachingService.cacheUnregisteredPurchases(this.unregisteresPurchases);
                }
              }
            },
            {
              text: this.translate.instant('STORE.AGAIN'),
              role: 'confirm',
              handler: () => {
                this.putPurchaseToDB(loading, tuneid, collectionid, data.sku, data.price, data.currency);
              }
            }
          ],
        });

        await alert.present();
        loading.dismiss();
      }
    });
  }

  async purchaseCollection(collectionSku: CollectionSku, sku: GlassfySku) {
    if(collectionSku.isPurchased) {
      return;
    }

    if(!this.isAuth) {
      this.showNotLoggedInAlert();
      return;
    }

    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

    try {

      await Glassfy.purchaseSku({ sku });

      //Register it on band-on-server
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      const data = {
        collectionid: collectionSku.collection.id,
        sku: sku.skuId,
        price: sku.product.price,
        currency: sku.product.currencyCode
      };

      this.httpClient.put(`${environment.apiURL}/users/${this.authService.getUserID()}/purchase`, data, {headers})
      .subscribe({
        next: () => {
          this.libraryService.loadUserLibraryFromServer(true);
          loading.dismiss();
        },
        error: () => {
          //TODO: Safety Mechanism here!
          loading.dismiss();
        }
      });

    } catch (e) {
      const alert = await this.alertController.create({
        header: this.translate.instant('STORE.PURCHASEFAILED'),
        message: this.translate.instant('STORE.PURCHASEFAILEDMSG'),
        buttons: ['OK']
      });
      await loading.dismiss();
      await alert.present();
    }
  }


  handleExistingPermissions(permissions: GlassfyPermission[]) {
    const perms: GlassfyPermission[] = [];
    const purchs: GlassfyAccountableSku[] = [];
    for (const perm of permissions) {
      if (perm.isValid) {
        purchs.push(...perm.accountableSkus);
        perms.push(perm);
      }
    }
    this.purchases.next(purchs);
    this.permissions.next(perms);
  }

  async restore() {
    const permissions = await Glassfy.restorePurchases();
  }

  isTunePurchased(tune: SimpleTuneModel): boolean {
    const userTunes = this.libraryService.userTunesBehaviour.getValue();
    if(userTunes && userTunes.length>0) {
      return userTunes.includes(tune.id);
    }
    return tune.isdemo;
  }

  isCollectionPurchased(collection: Collection): boolean {
    const userCollections = this.libraryService.userCollectionsBehaviour.getValue();
    if(userCollections && userCollections.length>0) {
      return userCollections.includes(collection.id);
    }
    return false;
  }

  getTuneSku(tune: SimpleTuneModel): TuneSku {
    return this.tuneSkus.find(ts => ts.tune.id===tune.id);
  }

  getCollectionSku(collection: Collection): CollectionSku {
    return this.collectionSkus.find(cs => cs.collection.id===collection.id);
  }

}
