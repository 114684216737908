<ion-grid class="tab-buttons ion-no-margin tab-bar">
  <ion-row class="ion-no-margin">
    <ion-col size="4">
      <ion-button auto-hide="false" (click)="openMixView()" expand="full" [color]="mixColor" fill="clear">
        Mix
      </ion-button>
    </ion-col>
    <ion-col size="4">
      <ion-button auto-hide="false" (click)="openMainView()" expand="full" [color]="mainColor" fill="clear">
        Info
      </ion-button>
    </ion-col>
    <ion-col size="4">
      <ion-button auto-hide="false" (click)="openArrangementView()" expand="full" [color]="arrangementColor" fill="clear">
        Arrangement
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>

<!--<div class="divider"></div>-->

<app-time-slider></app-time-slider>

<ion-toolbar>
  <ion-buttons slot="start">
    <ion-button auto-hide="false" (click)="jumpToPreviousPart()" *ngIf="!isLooping">
      <ion-icon name="play-skip-back" size="large"></ion-icon>
    </ion-button>
    <ion-button auto-hide="false" (click)="jumpToLoopBegin()" *ngIf="isLooping">
      <ion-icon name="play-skip-back" size="large"></ion-icon>
    </ion-button>
    <ion-icon *ngIf="isStartLoopPart" src="/assets/icon/Wiederholunga.svg" size="large"> </ion-icon>
  </ion-buttons>
  <ion-title class="ion-text-center ion-align-items-center">
    {{ currentPart }}
    <ion-icon name="caret-forward" *ngIf="isInPickup" style="transform: translateY(3px);"></ion-icon>
    <ion-text *ngIf="isInPickup">{{ nextPart }}</ion-text>
  </ion-title>
  <ion-buttons slot="end">
    <ion-icon *ngIf="isEndLoopPart" src="/assets/icon/Wiederholungb.svg" size="large"> </ion-icon>
    <ion-button auto-hide="false" (click)="jumpToNextPart()" [disabled]="!hasNextPart" *ngIf="!isLooping && (hasNextPart || !hasNextTune)">
      <ion-icon name="play-skip-forward" size="large"></ion-icon>
    </ion-button>
    <ion-button auto-hide="false" (click)="loopNextPart()" [disabled]="!hasNextPart || nextPartLoop" *ngIf="isLooping">
      <ion-icon name="return-up-forward-outline" size="large"></ion-icon>
    </ion-button>
    <ion-button auto-hide="false" (click)="jumpToNextTune()" *ngIf="!isLooping && !hasNextPart && hasNextTune">
      <ion-icon name="arrow-redo" size="large"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<!--<ion-toolbar>
  <app-tempo-slider [ticks]="[0, 25, 50, 75, 100]" [minTempo]="20" [maxTempo]="200"></app-tempo-slider>
</ion-toolbar>-->

<ion-toolbar class="ion-no-margin ion-no-padding control-bar" style="padding-bottom: 10px">
  <ion-grid class="ion-no-margin ion-no-padding">
    <ion-row class="ion-align-items-center" id="media-controls">
      <ion-col class="ion-align-self-center" size="2">
        <ion-button fill="clear" auto-hide="false" (click)="toggleCountOff()" *ngIf="hasCountOff" >
          <ion-icon src="/assets/icon/CountOff.svg" [ngClass]="{'countOff-button': countOffActive, 'countOff-button-disabled': !countOffActive}"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size="3">
        <ion-button fill="clear" auto-hide="false" [color]="recButtonColor" size="large" (click)="setupRecording()" [disabled]="isRecDisabled">
          <ion-icon name="radio-button-on"></ion-icon>
        </ion-button>
        <!-- <ion-button fill="clear" auto-hide="false" (click)="stop()">
          <ion-icon name="square"></ion-icon>
        </ion-button>-->
      </ion-col>
      <ion-col size="2">
        <ion-button fill="clear" auto-hide="false" (click)="playPause()" >
          <ion-icon *ngIf="!isPlaying" name="play" size="large" class="play-icon"></ion-icon>
          <ion-icon *ngIf="isPlaying" name="pause" size="large" class="play-icon"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size="3">
        <ion-button *ngIf="!isLooping" fill="clear" color="light" auto-hide="false" (click)="loop()">
          <ion-icon name="repeat"></ion-icon>
        </ion-button>
        <ion-button *ngIf="isLooping" fill="clear" color="primary" auto-hide="false" (click)="resetLoop()">
          <ion-icon name="repeat"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size="2">
        @if(hasMultipleSpeeds || !isWeb) {
          <ion-button fill="clear" (click)="showSpeedsPopover()">
            <ion-icon src="assets/icon/speedtest.svg"></ion-icon>
            <!--{{ currentSpeedString }}-->
          </ion-button>
          <ion-item class="select-container ion-hide">
            <ion-label class="ion-text-center">{{ 'PLAYER.SPEED' | translate }}</ion-label>

            <ion-select (ionChange)="selectSpeed($event)" #speedSelect
                [value]="currentSpeed"
                interface="alert"
                [label]="speedString"
                [interfaceOptions]="{ cssClass: 'band-on-alert'}"
            >
              <ion-select-option *ngFor="let s of speeds" [value]="s">
                {{ s.speed }}bpm
              </ion-select-option>
            </ion-select>
          </ion-item>
        }
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-toolbar>


