<div class="account-container" *ngIf="isAuth" >
  <div class="avatar-container">
    <ion-avatar>
      <ion-icon *ngIf="!userPicture"  name="help-circle" class="empty-avatar"></ion-icon>
      <img *ngIf="userPicture" [src]="userPicture?.data"/>
    </ion-avatar>

    <!-- edit icon -->
    <div class="edit-container" (click)="openImgOptionSelection()">
      <ion-icon name="camera-outline"></ion-icon>
    </div>
  </div>

  <div class="account-text">
    <ion-text class="name ion-text-no-wrap">{{ name }}</ion-text>
    <ion-text class="username ion-text-no-wrap">{{ username }}</ion-text>
    <ion-text class="email">{{ email }}</ion-text>
  </div>
</div>

@if(isAuth) {
  <div class="account-details-container">
    <ion-list inset="false" class="ion-margin-bottom ion-margin-top">
      <ion-item [button]="true" class="ion-no-padding" (click)="showProfileQRCode()">
        <ion-icon aria-hidden="true" name="qr-code" slot="start" color="light"></ion-icon>
        <ion-label>{{ 'SIDEMENU.SHOWQR' | translate }}</ion-label>
      </ion-item>

      <ion-item [button]="true" class="ion-no-padding" routerLink="/collection/user/messages">
        <ion-icon src="assets/icon/chat_bubble_black_24dp.svg" slot="start" aria-hidden="true" color="light"></ion-icon>
        <ion-label>
          {{ 'SIDEMENU.MESSAGES' | translate }}
        </ion-label>
        @if(messagesNotificationCount>0) {
          <ion-badge slot="end">{{ messagesNotificationCount }}</ion-badge>
        }
      </ion-item>
      <ion-item class="ion-no-padding" routerLink="/collection/user/user-contacts" detail="true" routerDirection="forward" button>
        <ion-icon aria-hidden="true" src="assets/icon/contact.svg" slot="start" color="light"></ion-icon>
        <ion-label>
          {{ 'SIDEMENU.MYCONTACTS' | translate }}
        </ion-label>
        @if(contactsNotificationCount>0) {
          <ion-badge slot="end">{{ contactsNotificationCount }}</ion-badge>
        }
      </ion-item>
      <ion-item [button]="true" class="ion-no-padding" routerLink="/collection/user/group-list">
        <ion-icon aria-hidden="true" name="people" slot="start" color="light"></ion-icon>
        <ion-label>
          {{ 'SIDEMENU.MYGROUPS' | translate }}
        </ion-label>
      </ion-item>
      <!--<ion-item [button]="true" class="ion-no-padding">
        <ion-icon aria-hidden="true" src="assets/icon/library_music_black_24dp.svg" slot="start" color="light"></ion-icon>
        <ion-label>
          {{ 'SIDEMENU.MYMUSIC' | translate }}
        </ion-label>
      </ion-item>-->
    </ion-list>
  </div>

  <div class="account-details-container">
    <ion-list-header style="margin-left: 0;">
      {{ 'SIDEMENU.MYDATA' | translate }}
    </ion-list-header>
    <ion-list inset="false">
      <!-- display name -->
      <form [formGroup]="nameForm">
        <ion-item class="ion-no-padding">
          <ion-label class="ion-no-margin">
            <ion-input #nameInput
              label = "{{ 'PROFILE.NAME' | translate }}"
              labelPlacement="stacked"
              formControlName="name"
              [value]="name"
              [readonly]="!editName"
              [class.active-input]="editName"
              (ionBlur)="cancelNameChange()"
            >
            </ion-input>
            @if(editName && ((nameForm.get("name").invalid && nameForm.get("name").touched) || nameForm.get("name").dirty)) {
              @if(nameForm.get("name").errors?.required) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: nameString } }}</ion-text>
              }
              @if(nameForm.get("name").errors?.minlength) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.MINLENGTH' | translate: {min: 3} }}</ion-text>
              }
            }
          </ion-label>
          @if(!editName) {
            <ion-button color="primary" fill="clear" (click)="triggerNameEdit()" slot="end">
              {{ 'PROFILE.EDIT' | translate }}
            </ion-button>
          } @else {
            <ion-button color="primary" fill="clear" (click)="changeName()" slot="end" [disabled]="!nameForm.valid">
              {{ 'PROFILE.CHANGE' | translate }}
            </ion-button>
          }
        </ion-item>
      </form>

      <!-- username -->
      <form [formGroup]="usernameForm">
        <ion-item class="ion-no-padding">
          <ion-label class="ion-no-margin">
            <ion-input #usernameInput
              label = "{{ 'PROFILE.USERNAME' | translate }}"
              labelPlacement="stacked"
              formControlName="username"
              [value]="username"
              [readonly]="!editUsername"
              [class.active-input]="editUsername"
              (ionBlur)="cancelUsernameChange()"
            >
            </ion-input>
            @if(editUsername && ((usernameForm.get("username").invalid && usernameForm.get("username").touched) || usernameForm.get("username").dirty)) {
              @if(usernameForm.get("username").errors?.required) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: usernameString } }}</ion-text>
              }
              @if(usernameForm.get("username").errors?.minlength) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.MINLENGTH' | translate: {min: 3} }}</ion-text>
              }
              @if(usernameForm.get("username").errors?.usernameTaken && usernameForm.get("username").value !== username) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.USERNAMETAKEN' | translate }}</ion-text><br />
                @if(suggestedUsernames.length > 0) {
                  <ion-text class="text-danger">{{ 'REGISTER.SUGGESTIONS' | translate }}:</ion-text>
                  <ion-text class="text-danger">
                    @for (suggestion of suggestedUsernames; track $index) {
                      @if($index < suggestedUsernames.length -1) {
                        {{ suggestion }},
                      } @else {
                        {{ suggestion }}
                      }
                    }
                  </ion-text>
                }
              }
            }
          </ion-label>
          @if(!editUsername) {
            <ion-button color="primary" fill="clear" (click)="triggerUsernameEdit()" slot="end">
              {{ 'PROFILE.EDIT' | translate }}
            </ion-button>
          } @else {
            <ion-button color="primary" fill="clear" (click)="changeUsername()" slot="end" [disabled]="!usernameForm.valid">
              {{ 'PROFILE.CHANGE' | translate }}
            </ion-button>
          }
        </ion-item>
      </form>

      <!-- email -->
      <form [formGroup]="emailForm">
        <ion-item class="ion-no-padding">
          <ion-label class="ion-no-margin">
            <ion-input #emailInput
              label = "{{ 'PROFILE.EMAIL' | translate }}"
              labelPlacement="stacked"
              formControlName="email"
              inputmode="email"
              [value]="email"
              [readonly]="!editEmail"
              [class.active-input]="editEmail"
              (ionBlur)="cancelEmailChange()"
            >
            </ion-input>
            @if(editEmail && ((emailForm.get("email").invalid && emailForm.get("email").touched) || emailForm.get("email").dirty)) {
              @if(emailForm.get("email").errors?.required) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: emailString } }}</ion-text>
              }
              @if(emailForm.get("email").errors?.pattern) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.PLVALIDMAIL' | translate }}</ion-text>
              }
            }
          </ion-label>
          @if(!editEmail) {
            <ion-button color="primary" fill="clear" (click)="triggerEmailEdit()" slot="end">
              {{ 'PROFILE.EDIT' | translate }}
            </ion-button>
          } @else {
            <ion-button color="primary" fill="clear" (click)="changeEmail()" slot="end" [disabled]="!emailForm.valid">
              {{ 'PROFILE.CHANGE' | translate }}
            </ion-button>
          }
        </ion-item>
      </form>


      <!-- password -->
      <form [formGroup]="pwdForm">
        <ion-item class="ion-no-padding" [lines]="editPassword ? 'none': 'full'">
          <ion-label class="ion-no-margin">
            <ion-input #oldPasswordInput
              label = "{{ 'PROFILE.PASSWORD' | translate }}"
              labelPlacement="stacked"
              formControlName="oldPassword"
              value="test"
              type="password"
              autocomplete="current-password"
              [readonly]="!editPassword"
              [class.active-input]="editPassword"
            >
            </ion-input>
            @if(editPassword && ((pwdForm.get("oldPassword").invalid && pwdForm.get("oldPassword").touched) || pwdForm.get("oldPassword").dirty)) {
              @if(pwdForm.get("oldPassword").errors?.required) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: passwordString } }}</ion-text>
              }
            }

          </ion-label>
          @if(!editPassword) {
            <ion-button color="primary" fill="clear" (click)="triggerPasswordEdit()" slot="end">
              {{ 'PROFILE.EDIT' | translate }}
            </ion-button>
          } @else {
            <ion-button color="primary" fill="clear" (click)="cancelPasswordChange()" slot="end">
              {{ 'PROFILE.CANCEL' | translate }}
            </ion-button>
            <ion-button color="primary" fill="clear" (click)="changePassword()" slot="end" [disabled]="!pwdForm.valid">
              {{ 'PROFILE.CHANGE' | translate }}
            </ion-button>
          }
        </ion-item>

        <ion-item class="ion-no-padding" lines="none" [class.ion-hide]="!editPassword">
          <ion-label class="ion-no-margin">
            <app-show-hide-password color="light">
              <ion-input #newPasswordInput
                label = "{{ 'PROFILE.NEWPWD' | translate }}"
                labelPlacement="stacked"
                formControlName="newPassword"
                value="test"
                type="password"
                autocomplete="new-password"
                [class.active-input]="editPassword"
              >
              </ion-input>
            </app-show-hide-password>
            @if(editPassword && ((pwdForm.get("newPassword").invalid && pwdForm.get("newPassword").touched) || pwdForm.get("newPassword").dirty)) {
              @if(pwdForm.get("newPassword").errors?.required) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: newPasswordString } }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.minlength) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.MINLENGTH' | translate: {min: 8} }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.hasNumber) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.NEEDSNUMBER' | translate: {min: 1} }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.hasCapitalCase) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.NEEDSCAPITAL' | translate: {min: 1} }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.hasSmallCase) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.NEEDSSMALL' | translate: {min: 1} }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.hasSpecialCharacters) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.NEEDSSPECIAL' | translate: {min: 1} }}</ion-text><br>
              }
              @if(pwdForm.get("newPassword").errors?.required) {
                <ion-text class="text-danger">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: passwordString} }}</ion-text>
              }
            }
          </ion-label>
        </ion-item>

        <ion-item class="ion-no-padding" [class.ion-hide]="!editPassword">
          <ion-label class="ion-no-margin">
            <app-show-hide-password color="light">
              <ion-input #confirmPasswordInput
                label = "{{ 'PROFILE.CONFIRMPWD' | translate }}"
                labelPlacement="stacked"
                formControlName="confirmPassword"
                value="test"
                type="password"
                autocomplete="new-password"
                [class.active-input]="editPassword"
              >
              </ion-input>
            </app-show-hide-password>
            @if(editPassword && ((pwdForm.get("newPassword").invalid && pwdForm.get("newPassword").touched) || pwdForm.get("newPassword").dirty)) {
              @if(pwdForm.get("newPassword").errors?.required) {
                <ion-text class="text-danger ion-text-wrap">{{ 'REGISTER.REQUIREDFIELD' | translate: {field: newPasswordString } }}</ion-text>
              }
              @if(pwdForm.hasError('passwordsNotMatch')) {
                <ion-text class="text-danger ion-text-wrap">{{ 'PROFILE.PWDNOTMATCH' | translate }}</ion-text>
              }
            }
            </ion-label>
        </ion-item>
      </form>

      <!-- instruments -->
      <ion-item class="ion-no-padding">
        <div class="option-container">
          <div class="option-name">
            <ion-text class="label">{{ 'PROFILE.INSTRUMENTS' | translate }}</ion-text>
            <ion-text class="value">{{ userInstrumentsString }}</ion-text>
          </div>
        </div>
        <ion-button color="primary" fill="clear" (click)="showInstrumentPopover()" slot="end">
          {{ 'PROFILE.EDIT' | translate}}
        </ion-button>
      </ion-item>
      <ion-item class="ion-hide">
        <ion-label>{{ 'SIDEMENU.INSTRUMENTS' | translate }}</ion-label>
        <ion-select #instrumentSelect
            (ionChange)="selectInstruments($event)"
            [compareWith]="compareWith"
            [value]="userInstruments"
            multiple="true"
            [interfaceOptions]="{ cssClass: 'band-on-alert'}"
            label="{{ 'SIDEMENU.INSTRUMENTS' | translate }}">
          <ion-select-option *ngFor="let i of instruments" [value]="i">{{ getInstrumentDesignation(i) }}</ion-select-option>
        </ion-select>
      </ion-item>

      <!-- language -->
      <ion-item class="ion-no-padding">
        <div class="option-container">
          <div class="option-name">
            <ion-text class="label">{{ 'SIDEMENU.LANGUAGE' | translate }}</ion-text>
            <ion-text class="value">{{ currentLanguageString }}</ion-text>
          </div>
        </div>
        <ion-button color="primary" (click)="showLanguagesPopover()" fill="clear" slot="end">
          {{ 'PROFILE.EDIT' | translate}}
        </ion-button>
      </ion-item>
      <ion-item class="select-container ion-hide">
        <ion-label>{{ 'SIDEMENU.LANGUAGES' | translate }}</ion-label>
        <ion-select (ionChange)="selectLanguage($event)" #languageSelect [value]="currentLanguage" interface="alert" [interfaceOptions]="{ cssClass: 'band-on-alert'}" label="{{ 'SIDEMENU.LANGUAGES' | translate }}">
          <ion-select-option *ngFor="let l of languages" [value]="l.value">{{ l.text }}</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item class="ion-no-padding">
        <div class="option-container">
          <div class="option-name">
            <ion-text class="value">{{ 'PROFILE.NEWSLETTER' | translate }}</ion-text>
          </div>
        </div>
        <ion-toggle slot="end" [(ngModel)]="newsletter"></ion-toggle>
      </ion-item>

      <ion-item class="ion-no-padding">
        <div class="option-container">
          <div class="option-name">
            <ion-text class="value">{{ 'PROFILE.MYPURCHASES' | translate }}</ion-text>
          </div>
        </div>
        <div slot="end">
          <ion-button color="primary" (click)="refreshPurchases()" fill="clear" slot="end">
            {{ 'PROFILE.RELOAD' | translate}}
          </ion-button>
          <ion-button color="primary" (click)="showPurchases()" fill="clear" slot="end">
            {{ 'PROFILE.SHOWALL' | translate}}
          </ion-button>
        </div>
      </ion-item>


    </ion-list>
  </div>
}

<!-- sign out -->
<div class="delete-button-container" *ngIf="isAuth">
  <ion-button expand="full" fill="clear" class="signout-button" (click)="deleteProfile()">
    {{ 'PROFILE.DELETE' | translate }}
  </ion-button>
</div>
<div class="signout-button-container" *ngIf="isAuth">
  <ion-button expand="full" class="signout-button" (click)="logout()">
    {{ 'PROFILE.LOGOUT' | translate }}
  </ion-button>
</div>
