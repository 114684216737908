import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { serviceLoader, createTranslateLoader } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppComponent } from './app/app.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SharedComponentsModule } from 'src/app/modules/shared-components/shared-components.module';
import { Capacitor } from '@capacitor/core';
import { provideAuth, initializeAuth, indexedDBLocalPersistence, getAuth } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { TuneInfoService } from './app/services/audio/tune-info.service';
import { AudioService } from './app/services/audio/audio.service';
import { AuthInterceptor } from './app/services/auth/auth-interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { AppLoaderService } from './app/services/app-loader.service';
import { UserDataService } from './app/services/storage/user-data.service';
import { StoreService } from './app/services/store/store.service';
import { LibraryService } from 'src/app/services/library.service';
import { IonicRouteStrategy, IonicModule } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { AudioDownloadService } from './app/services/audio/audio-download.service';
import { StorageService } from './app/services/storage/storage.service';
import { AuthenticationService } from './app/services/auth/authentication.service';
import { CachingService } from './app/services/caching/caching.service';
import { ConnectivityService } from './app/services/connectivity/connectivity.service';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

import 'hammerjs';
import { DatePipe } from '@angular/common';


bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, IonicModule.forRoot({
            backButtonText: ''
        }), AppRoutingModule, TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }), provideFirebaseApp(() => initializeApp(environment.firebase)), provideAuth(() => {
            if (Capacitor.isNativePlatform()) {
                return initializeAuth(getApp(), {
                    persistence: indexedDBLocalPersistence
                });
            }
            else {
                return getAuth();
            }
        }), SharedComponentsModule, IonicStorageModule.forRoot({
            driverOrder: [Drivers.IndexedDB]
        }), YouTubePlayerModule),
        NativeStorage,
        ConnectivityService,
        CachingService,
        AuthenticationService,
        StorageService,
        AudioDownloadService,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        LibraryService,
        StoreService,
        UserDataService,
        {
            provide: APP_INITIALIZER,
            useFactory: serviceLoader,
            deps: [AppLoaderService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AudioService,
        TuneInfoService,
        EmailComposer,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        DatePipe,
    ]
})
  .catch(err => console.log(err));

defineCustomElements(window);
if (environment.production) {
  enableProdMode();
}
