import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { SimpleTuneModel, Tune } from 'bandon-shared';

@Injectable({
  providedIn: 'root'
})
export class BandONTranslationsService {

  constructor(
    private authService: AuthenticationService
  ) { }

  getTuneTitle(tune: SimpleTuneModel | Tune) {
    if(this.authService.user && tune) {
      const translation = tune.translations.find(e => e.languageid===this.authService.user.language);
      if(translation && translation.title) {
        return translation.title;
      }
      return tune.title;
    } else if(tune) {
      return tune.title;
    }
    return '';
  }
}
