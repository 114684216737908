import { Injectable, inject } from '@angular/core';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { CachingService } from '../caching/caching.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, switchMap, tap, filter, from, interval, takeWhile } from 'rxjs';
import { UserMessage } from 'bandon-shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserMessagesService {
  connectivityService = inject(ConnectivityService)
  httpClient = inject(HttpClient)
  authService = inject(AuthenticationService)
  cachingService = inject(CachingService)
  router = inject(Router)
  translate = inject(TranslateService)

  public notificationCount = 0;

  public messagesBehaviour = new BehaviorSubject<UserMessage[]>([]);
  public messages$ = this.messagesBehaviour.asObservable();

  private isOnline = false;
  private isAuth = false;
  timerSubscription: any;


  constructor() {
    this.connectivityService.appIsOnline$.subscribe(online => {
      this.isOnline = online;
    });

    this.authService.isAuthenticated.subscribe(auth => {
      if (auth===this.isAuth) {
        return;
      }
      this.isAuth = auth;
    });

    this.authService.user$.subscribe(() => {
      this.refreshMessages(true)
    })

    this.startTimer()
  }

  pauseTimer() {
    if(this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }
  }

  startTimer() {
    if (!this.timerSubscription) {
      this.refreshMessages();
      this.timerSubscription = interval(60*1000).pipe(
        takeWhile(() => true) // This ensures the timer keeps running indefinitely until the component is destroyed
      ).subscribe(() => {
        if(this.isAuth) {
          this.refreshMessages();
        }
      });
    }
  }

  refreshMessages(forceRefresh = false) {
    if(this.authService.user && this.isAuth) {
      this.getData(`${environment.apiURL}/users/${this.authService.user.uid}/messages`, true)
        .subscribe({
          next: data => {
            this.messagesBehaviour.next(data);
            this.calcNotificationCount();
          }
        })
    } else {
      this.messagesBehaviour.next([]);
    }
  }

  markMessageAsRead(message: UserMessage) {
    if(this.authService.user && this.isAuth) {
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      const formData = new FormData();
      formData.append('state', '1');
      this.httpClient.post<UserMessage>(`${environment.apiURL}/users/${this.authService.user.uid}/messages/${message.id}`, formData, {headers})
      .subscribe({
        next: res => {
          message.state = 1
          this.calcNotificationCount();
        },
        error: err => {
          console.error(err);
        }
      });
    }
  }

  deleteMessage(message: UserMessage) {
    if(this.authService.user && this.isAuth) {
      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      this.httpClient.delete<UserMessage>(`${environment.apiURL}/users/${this.authService.user.uid}/messages/${message.id}`, {headers})
      .subscribe({
        next: res => {
          this.messagesBehaviour.value.splice(this.messagesBehaviour.value.indexOf(message), 1)
          this.calcNotificationCount();
        },
        error: err => {
          console.error(err);
        }
      });
    }
  }

  private getData(url, forceRefresh: boolean): Observable<any> {
    if(!this.isOnline) {
      return from(this.cachingService.getCachedRequest(url));
    }

    if(forceRefresh) {
      return this.callAndCache(url);
    } else {
      const storedValue = from(this.cachingService.getCachedRequest(url));
      return storedValue.pipe(
        switchMap(result => {
          if (!result) {
            // make an api call
            return this.callAndCache(url);
          } else {
            return of(result);
          }
        })
      );
    }
  }

  private callAndCache(url): Observable<any> {
    let headers = new HttpHeaders();
    if(!this.isAuth) {
      headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    } else {
//      console.log('get Tracks from Server, ', url);
      headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    }
    return this.httpClient.get(url, {headers}).pipe(
      tap(res => {
        this.cachingService.cacheRequest(url, res);
      })
    );
  }

  private calcNotificationCount() {
    this.notificationCount = this.messagesBehaviour.value.filter(d => d.state===0).length;

  }
}
