import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Collection } from 'bandon-shared';
import { CachedImgSource, CachedImageComponent } from '../cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { Router, RouterLink } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'app-collection-item',
    templateUrl: './collection-item.component.html',
    styleUrls: ['./collection-item.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        RouterLink,
        CachedImageComponent,
    ],
})
export class CollectionItemComponent  implements OnInit, OnDestroy {

  @Input() collection: Collection;
  @Input() routerLink: string;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) { }

  get collectionImgSrc(): CachedImgSource {
    if(this.collection && this.collection.picture) {
      const token = 'Bearer '+environment.apiKey;
      return { url: environment.apiURL+'/photoImg/'+this.collection.picture.id, path: this.collecitonImgPath, token };
    }
    return { url: '', path: '', token: '' };
  }

  get collecitonImgPath(): string {
    if(this.collection && this.collection.picture) {
      return this.collection.picture.path;
    }
    return '';
  }

  get designation(): string{
    if(this.authService.user && this.collection) {
      const translation = this.collection.translations.find(e => e.languageid===this.authService.user.language);
      if(translation) {
        return translation.designation;
      }
      return this.collection.designation;
    } else if(this.collection) {
      return this.collection.designation;
    }
    return '';
  }

  get description(): string {
    if(this.authService.user && this.collection) {
      const translation = this.collection.translations.find(e => e.languageid===this.authService.user.language);
      if(translation) {
        return translation.description.slice(0, 200);
      }
      return this.collection.description.slice(0, 200);
    } else if(this.collection) {
      return this.collection.description.slice(0, 200);
    }
    return '';
  }

/*  get routerLink(): string {
    return `/collection/library/collection/${this.collection.id}`;
  }*/


  ngOnInit() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}
