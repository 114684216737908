<ion-item (click)="openTune()" routerDirection="forward" detail="false" #tuneItem>
  <ion-icon slot="start" name="add-circle" size="large" class="add-icon" *ngIf="showRemove && !tune" (click)="triggerAdd()" aria-hidden="true"></ion-icon>
  <ion-icon slot="start" name="remove-circle" color="primary" size="large" *ngIf="showRemove && tune" (click)="triggerRemove()" aria-hidden="true"></ion-icon>
  <ion-thumbnail slot="start" class="tune-img" *ngIf="tune" aria-hidden="true">
    <app-cached-image [src]="tuneImgSrc"></app-cached-image>
  </ion-thumbnail>
  <ion-label class="ion-text-wrap" *ngIf="tune">
    <h2 aria-level="3" [ariaLabel]="ariaText" role="button">
      {{ title }}
    </h2>
    <h3 *ngIf="tune.subtitle && showSubtitle" aria-hidden="true">{{ tune.subtitle }}</h3>
    <h3 aria-hidden="true">{{ description }}</h3>
    <p *ngIf="tune.searchNote && showSearchHints" aria-hidden="true">{{ tune.searchNote }}</p>
  </ion-label>
  <ion-label class="ion-text-wrap" *ngIf="!tune" color="primary">
    {{ 'PLAYLISTS.ADDTUNE' | translate }}
  </ion-label>
  <app-load-tune-button class="ion-justify-content-center"
      slot="end"
      [fileState]="downloadState"
      [progress]="downloadProgress"
      [tuneID]="tune.id"
      (click)="downloadTune($event)"
      (deleteFiles)="deleteFiles()"
      (startDownload)="downloadFiles()"
      *ngIf="!isWeb && tune && !showRemove && !showAddToPl"></app-load-tune-button>
  <ion-button *ngIf="showAddToPl" fill="clear" class="add-button">
    <ion-icon slot="end" name="add-circle-outline" size="large" *ngIf="!isInPlaylist" ></ion-icon>
    <ion-icon slot="end" name="checkmark" size="large" *ngIf="isInPlaylist" ></ion-icon>
  </ion-button>
  <ion-reorder slot="end" [class.ion-hide]="!isReordable" ></ion-reorder>
</ion-item>



