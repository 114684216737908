import { NotificationService } from './notifications/notification.service';
import { StoreService } from './store/store.service';
import { AudioService } from 'src/app/services/audio/audio.service';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './auth/authentication.service';
import { AudioDownloadService } from './audio/audio-download.service';
import { CachingService } from './caching/caching.service';
import { LibraryService } from './library.service';
import { TuneInfoService } from './audio/tune-info.service';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {

  constructor(
    private audioService: AudioService,
    private storeServie: StoreService,
    private audioDownloadService: AudioDownloadService,
    private cachingService: CachingService,
    private authService: AuthenticationService,
    private libraryService: LibraryService,
    private notificationService: NotificationService,
    private tuneInfoService: TuneInfoService
  ) { }

  async init() {
    await this.cachingService.init();
    await this.audioDownloadService.init();
    await this.audioService.init();
    await this.tuneInfoService.init();
    await this.libraryService.init();
    this.notificationService.init();
//    await this.storeServie.initGlassfy();
//    this.authService.checkAuthenticated();
    return Promise.resolve().then(() => {});
  }
}
