import { Injectable, inject } from '@angular/core';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, from, switchMap, of, tap, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { CachingService } from '../caching/caching.service';
import { UserGroup } from 'bandon-shared';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  connectivityService = inject(ConnectivityService)
  httpClient = inject(HttpClient)
  authService = inject(AuthenticationService)
  cachingService = inject(CachingService)
  router = inject(Router)
  translate = inject(TranslateService)
  alertController = inject(AlertController)
  sanitizer = inject(DomSanitizer)

  public groupsBehaviour = new BehaviorSubject<UserGroup[]>([]);
  public groups$ = this.groupsBehaviour.asObservable();

  private isOnline = false;
  private isAuth = false;

  constructor() {
    this.connectivityService.appIsOnline$.subscribe(online => {
      this.isOnline = online;
    });

    this.authService.isAuthenticated.subscribe(auth => {
      if (auth===this.isAuth) {
        return;
      }
      this.isAuth = auth;
    });

    this.authService.user$.subscribe(() => {
      this.refreshGroups(true)
    })
  }

  refreshGroups(forceRefresh = false) {
    if(this.authService.user && this.isAuth) {
      this.getData(`${environment.apiURL}/usergroups`, true)
        .subscribe({
          next: data => {
            this.groupsBehaviour.next(data);
            this.groupsBehaviour.value.forEach(group => this.checkIfGroupPictureExists(group))
          }
        })
    } else {
      this.groupsBehaviour.next([]);
    }
  }

  getGroup(groupID: number) {
    return this.groupsBehaviour.value.find(g => g.id===groupID);
  }

  updateGroup(group: UserGroup) {
    let groups = this.groupsBehaviour.value;
    let existingGroup = groups.find(g => g.id === group.id);
    if(existingGroup) {
      groups.splice(groups.indexOf(existingGroup), 1)
    }
    groups.push(group)
    groups = groups.sort((g1, g2) => g1.designation.localeCompare(g2.designation));
    this.groupsBehaviour.next(groups);
  }

  public handleInvitation(group_id: string, addingUsername) {
    if(group_id && addingUsername && this.authService.user) {
      console.log(group_id, addingUsername)
      const token = this.authService.getIDToken();
      const headers = new HttpHeaders().set('Authorization', token);

      const queryParams = new HttpParams().set('addingUsername', addingUsername);

      this.httpClient.put(`${environment.apiURL}/usergroups/invitation/${group_id}`, null, { headers, params: queryParams })
        .subscribe({
          next: async resp => {
            this.refreshGroups();
            this.router.navigateByUrl('collection/user/group-list')
          },
          error: err => {
            let msg = this.translate.instant('GROUPS.ERROROTHER')
            if(err.error) {
              if(err.error.id === 1) {
                msg = this.translate.instant('GROUPS.ERROR1')
              } else if(err.error.id) {
                msg = this.translate.instant('GROUPS.ERROR7')
              } else if(err.error.id) {
                msg = this.translate.instant('GROUPS.ERROR2')
              }
            }
            this.presentAlert(msg)
          }
        });
    }
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: this.translate.instant('GROUPS.ERROR'),
      message: message,
      buttons: ['Action'],
    });

    await alert.present();

    this.router.navigateByUrl('collection/user/group-list')
  }


  private getData(url, forceRefresh: boolean): Observable<any> {
    if(!this.isOnline) {
      return from(this.cachingService.getCachedRequest(url));
    }

    if(forceRefresh) {
      return this.callAndCache(url);
    } else {
      const storedValue = from(this.cachingService.getCachedRequest(url));
      return storedValue.pipe(
        switchMap(result => {
          if (!result) {
            // make an api call
            return this.callAndCache(url);
          } else {
            return of(result);
          }
        })
      );
    }
  }

  private callAndCache(url): Observable<any> {
    let headers = new HttpHeaders();
    if(!this.isAuth) {
      headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    } else {
//      console.log('get Tracks from Server, ', url);
      headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    }
    return this.httpClient.get(url, {headers}).pipe(
      tap(res => {
        this.cachingService.cacheRequest(url, res);
      })
    );
  }

  refreshGroupPictures() {
    this.groupsBehaviour.value.forEach(group => this.checkIfGroupPictureExists(group, true))
  }

  checkIfGroupPictureExists(group: UserGroup, forceRefresh = false) {
    if(group.img && !group.imgSrc) {
      const filename = group.img;
      if(!forceRefresh) {
        Filesystem.readFile({
          directory: Directory.Cache,
          path: `${environment.groupImgCacheFolder}/${filename}`,
        }).then(result => {
          const fileType = filename.split('.').pop();
          group.imgSrc =  `data:image/${fileType};base64,${result.data}`
        }, async err => {
          this.downloadGroupPicture(group, filename);
        });
      } else {
        this.downloadGroupPicture(group, filename);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async downloadGroupPicture(group: UserGroup, filename: string) {
    console.log(`Download group image: ${group.img}`)
    try {
      const fileExists = await Filesystem.readFile({
        directory: Directory.Cache,
        path: `${environment.groupImgCacheFolder}/${filename}`
      });
      if (fileExists) {
        // If the file exists, delete it
        await Filesystem.deleteFile({
          path: `${environment.groupImgCacheFolder}/${filename}`,
          directory: Directory.Cache,
        });
      }
    } catch(error) {
      console.log('Error deleting group image');
    }

    const token = this.authService.getIDToken();
    const headers = new HttpHeaders().set('Authorization', token);
    this.httpClient.get(`${environment.apiURL}/usergroups/${group.id}/img`, { headers, responseType: 'blob'})
    .subscribe({
      next: async resp => {
        if (resp) {
          const base64Data = await this.convertBlobToBase64(resp) as string;
          Filesystem.writeFile({
            directory: Directory.Cache,
            path: `${environment.groupImgCacheFolder}/${filename}`,
            data: base64Data
          }).then( e => console.log('Group image saved'));
          let objectURL = URL.createObjectURL(resp);
          group.imgSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          console.log(`Updated group image source: `, group.imgSrc)
        }
      },
      error: err => {
        console.log('download group image Error');
      }
    });
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

}
