<ion-item>
  <!-- <div class="cover" [hidden]="!showCover" (click)="toggleCover()">
    <app-cached-image [src]="getTuneIconSrc(tune)"></app-cached-image>
  </div>
  <div class="cover-text" style="overflow-y: scroll; height: 100%; padding-bottom: 20px" [hidden]="showCover" (click)="toggleCover()">
    <ion-text [innerHTML]="coverText" color="light">
    </ion-text>
  </div> -->

  <ion-thumbnail slot="start" class="tune-img" [class.ion-hide]="!showCover" (click)="togglePreview()" #tuneCover>
    <app-cached-image [src]="tuneImgSrc"></app-cached-image>
    <ion-icon name="play" class="overlay-icon" *ngIf="tune.previewpath" ></ion-icon>
  </ion-thumbnail>
  <ion-thumbnail slot="start" class="tune-preview ion-text-center" [class.ion-hide]="showCover" (click)="togglePreview()" #tunePreview>
    <circle-progress
      [percent]="previewProgress"
      [innerStrokeWidth]="5"
      [radius]="15"
      [outerStrokeWidth]="5"
      [space]="-5"
      class="center"
      imageSrc="assets/icon/stop_red.svg"
      [imageHeight]="15"
      [imageWidth]="15"
      [showImage]="true">
    </circle-progress>
  </ion-thumbnail>

  <ion-label class="ion-text-wrap" (click)="showTuneDetails()">
    <h2 [class]="titleStyle">{{ title }}</h2>
    <h3>{{ description }}</h3>
    <p *ngIf="tune.searchNote" >{{ tune.searchNote }}</p>
  </ion-label>
  <ion-button slot="end" fill="solid" (click)="purchase()" *ngIf="tuneSku && !tuneSku.isPurchased" class="button-container"  [disabled]="!isOnline">
    {{ price | number: '1.0-2' | currency: currencyCode+' ' }}
  </ion-button>
  <ion-button slot="end" fill="outline"  *ngIf="tuneSku && tuneSku.isPurchased" disabled="true" class="button-container">
    {{ 'STORE.BOUGHT' | translate }}
  </ion-button>
</ion-item>
