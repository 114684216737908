/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AlertController, LoadingController, IonicModule } from '@ionic/angular';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';
import { TranslateModule } from '@ngx-translate/core';
import { ShowHidePasswordComponent } from '../../general/show-hide-password/show-hide-password.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-login-screen',
    templateUrl: './login-screen.component.html',
    styleUrls: ['./login-screen.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IonicModule,
        FormsModule,
        ShowHidePasswordComponent,
        RouterLink,
        TranslateModule,
    ],
})
export class LoginScreenComponent  implements OnInit, OnDestroy {

  _email: string;
  _password: string;

  isConnected = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private authService: AuthenticationService,
    private router: Router,
    private connService: ConnectivityService
  ) { }

  get isWeb() {
    return Capacitor.getPlatform() === 'web';
  }

  get isAndroid() {
    return Capacitor.getPlatform() === 'android';
  }

  get email() {
    return this._email;
  }

  set email(email: string) {
    this._email = email;
  }

  get password() {
    return this._password;
  }

  set password(password: string) {
    this._password = password;
  }

  get isSubmitEnabled(): boolean {
    if(this.email && this.email.length>0 && this.password && this.password.length>6) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.connService.appIsOnline$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(c => {
        this.isConnected = c;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async login() {
    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    if (Capacitor.getPlatform() === 'ios') {
      await SavePassword.promptDialog({
          username: this.email,
          password: this.password
      })
      .then(() => console.log('promptDialog success'))
      .catch((err) => console.error('promptDialog failure', err));
    }
    await loading.present();

    this.authService.login({email: this.email, password: this.password})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: async response => {
        loading.dismiss();

        this.router.navigateByUrl('collection', { replaceUrl: true, state: {animation: 'Login'} });
      },
      error: error => {
        loading.dismiss();
        this.showAlert('Login failed', 'Please try again!');
      }
    });

  }

  async loginWithApple() {
    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.authService.loginWithApple().then(observable => {
      observable
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: response => {
          loading.dismiss();
          this.router.navigateByUrl('collection', { replaceUrl: true });
        },
        error: error => {
          loading.dismiss();
          this.showAlert('Login failed', 'Please contact the band-on-Team: '+error);
        }
      });
    }).catch(error => {
      loading.dismiss();
//      this.showAlert('Login failed', 'Please contact the band-on-Team: '+error);
    });
  }

  async loginWithGoogle() {
    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.authService.loginWithGoogle().then(observable => {
      observable
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: response => {
          loading.dismiss();
          this.router.navigateByUrl('collection', { replaceUrl: true });
        },
        error: error => {
          loading.dismiss();
          this.showAlert('Login failed', 'Please contact the band-on-Team: '+error);
        }
      });
    })
    .catch(error => {
      this.showAlert('Login failed', 'Please contact the band-on-Team: '+error);
      loading.dismiss();
    });
  }

  async showAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      cssClass: 'band-on-alert',
      buttons: ['OK']
    });
    await alert.present();
  }
/*  openSignIn() {
    const provider = new OAuthProvider('apple.com');

    signInWithPopup(this.auth, provider).then((result: UserCredential) => {
      console.log(result);
    })
  }

  logout() {
    this.auth.signOut();
  }*/
}
