import { LoadTuneButtonComponent } from './../../components/general/load-tune-button/load-tune-button.component';
import { CachedImageComponent } from './../../components/general/cached-image/cached-image.component';
import { ArrangementViewComponent } from './../../components/arrangement-view/arrangement-view.component';
import { LevelIconComponent } from './../../components/level-icon/level-icon.component';
import { SideMenuComponent } from './../../components/side-menu/side-menu.component';
import { InstrumentItemComponent } from './../../components/instrument-item/instrument-item.component';
import { MixerComponent } from './../../components/mixer/mixer.component';
import { CollectionCoverComponent } from './../../components/collections/collection-cover/collection-cover.component';
import { ProfilePhotoOptionComponent } from './../../components/side-menu/profile-photo-option/profile-photo-option.component';
import { TuneItemComponent } from '../../components/general/tune-item/tune-item.component';
import { IonicModule } from '@ionic/angular';
import { TimeSliderComponent } from './../../components/time-slider/time-slider.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PlayerFooterComponent } from 'src/app/components/player-footer/player-footer.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ArrangementPartComponent } from 'src/app/components/arrangement-view/arrangement-part/arrangement-part.component';
import { ShowHidePasswordComponent } from 'src/app/components/general/show-hide-password/show-hide-password.component';
import { StoreTuneItemComponent } from 'src/app/components/store/store-tune-item/store-tune-item.component';
import { CollectionItemComponent } from 'src/app/components/general/collection-item/collection-item.component';
import { RecordingItemComponent } from 'src/app/components/recording-item/recording-item.component';
import { LoginScreenComponent } from 'src/app/components/user/login-screen/login-screen.component';
import { ShopWindowComponent } from 'src/app/components/store/shop-window/shop-window.component';
import { PlaylistItemComponent } from 'src/app/components/playlists/playlist-item/playlist-item.component';
import { PlaylistIconComponent } from 'src/app/components/playlists/playlist-icon/playlist-icon.component';
import { ImgCropperComponent } from 'src/app/components/general/img-cropper/img-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AddToPlaylistComponent } from 'src/app/components/playlists/add-to-playlist/add-to-playlist.component';
import { TuneDetailsComponent } from 'src/app/components/store/tune-details/tune-details.component';
import { SheetViewerComponent } from 'src/app/components/general/sheet-viewer/sheet-viewer.component';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import 'hammerjs';
import { HammerModule } from '@angular/platform-browser';
import { OfflineHeaderComponent } from 'src/app/components/general/offline-header/offline-header.component';
import { TempoSliderComponent } from 'src/app/components/tempo-slider/tempo-slider.component';

import { NgxSliderModule } from 'ngx-slider-v2';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        TranslateModule,
        NgCircleProgressModule.forRoot({
            animation: false,
            innerStrokeColor: 'var(--ion-color-secondary)',
            outerStrokeLinecap: 'butt',
            outerStrokeColor: 'var(--ion-color-primary)',
            showInnerStroke: true,
            showTitle: false,
            showUnits: false,
            showSubtitle: false
        }),
        ImageCropperModule,
        HammerModule,
        NgxExtendedPdfViewerModule,
        NgxSliderModule,
        TimeSliderComponent,
        TuneItemComponent,
        ProfilePhotoOptionComponent,
        CollectionCoverComponent,
        MixerComponent,
        InstrumentItemComponent,
        SideMenuComponent,
        LevelIconComponent,
        ArrangementViewComponent,
        PlayerFooterComponent,
        CachedImageComponent,
        LoadTuneButtonComponent,
        ArrangementPartComponent,
        ShowHidePasswordComponent,
        StoreTuneItemComponent,
        CollectionItemComponent,
        RecordingItemComponent,
        LoginScreenComponent,
        ShopWindowComponent,
        PlaylistItemComponent,
        PlaylistIconComponent,
        ImgCropperComponent,
        AddToPlaylistComponent,
        TuneDetailsComponent,
        SheetViewerComponent,
        OfflineHeaderComponent,
        TempoSliderComponent
    ],
    exports: [
        TimeSliderComponent,
        TuneItemComponent,
        TranslateModule,
        ProfilePhotoOptionComponent,
        CollectionCoverComponent,
        MixerComponent,
        InstrumentItemComponent,
        SideMenuComponent,
        LevelIconComponent,
        ArrangementViewComponent,
        PlayerFooterComponent,
        CachedImageComponent,
        LoadTuneButtonComponent,
        ArrangementPartComponent,
        ShowHidePasswordComponent,
        StoreTuneItemComponent,
        CollectionItemComponent,
        RecordingItemComponent,
        LoginScreenComponent,
        ShopWindowComponent,
        PlaylistItemComponent,
        PlaylistIconComponent,
        ImgCropperComponent,
        AddToPlaylistComponent,
        TuneDetailsComponent,
        SheetViewerComponent,
        OfflineHeaderComponent,
        TempoSliderComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class SharedComponentsModule { }
