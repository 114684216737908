// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  cleverReach: {
    clientid: '5iXCtI4glc',
    clientsecret: 'SUgRQjYyonWA75R2uuBhisL1aXPwELZZ',
    newsletterGroupID: 545686
  },
  firebase: {
    projectId: 'musican-345011',
    appId: '1:589593319665:web:fd363ad0c13dbfdd873150',
    storageBucket: 'musican-345011.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyAtQkQwejITZyMJZSgwdOyzndvM4dKh9oc',
    authDomain: 'musican-345011.firebaseapp.com',
    messagingSenderId: '589593319665',
  },
  production: true,
  apiURL: 'https://api.bandon.ch',
  apiKey: '7ada9becc7b3dd8944a24b0555af4e41768c977798b9b4ef40d1b2bf43aa8225',
  glassfyKey: '7c134dc09c304a25a94748b49efa1b95',
  imgCacheFolder: 'CACHED-IMG',
  sheetsCacheFolder: 'CACHED-SHEETS',
  avatarCacheFolder: 'STORED-AVATARS',
  groupImgCacheFolder: 'STORED-GROUP-IMG',
  minSpeedFactor: 0.5,
  maxSpeedFactor: 1.2,
  appVersion: '1.7.0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
